import {
  FIND_JOBS_V2,
  FIND_JOBS_V2_COMPLETED,
  FIND_JOBS_V2_FAILED, FIND_JOB_FOR_CANDIDATES, FIND_JOB_FOR_CANDIDATES_COMPLETED, FIND_JOB_FOR_CANDIDATES_FAILED,
  REMOVE_JOB_V2_POSTING, REMOVE_JOB_V2_POSTING_COMPLETED, REMOVE_JOB_V2_POSTING_FAILED,
  SET_JOB_FOR_ASSESSMENT
} from "../actions/jobsV2";
import {PAGE_INIT} from "../actions/pages";
import {toast} from "react-toastify";

export const defaultState = Object.freeze({
  jobForAssessment: null,

  jobs: [],
  findingJobs: false,
  findJobsError: "",
  totalCount: 0,

  jobForCandidates: null,
  findingJobForCandidates: false,
  findJobForCandidatesError: "",
});

const jobsV2Reducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return {...defaultState};
    }
    case FIND_JOBS_V2: {
      return {
        ...state,
        findingJobs: true,
        findJobsError: "",
      }
    }
    case FIND_JOBS_V2_COMPLETED: {
      const {results, total} = action.payload;
      return {
        ...state,
        findingJobs: false,
        jobs: results,
        totalCount: total,
      }
    }
    case FIND_JOBS_V2_FAILED: {
      const {message} = action.payload;
      return {
        ...state,
        findingJobs: false,
        findJobsError: message,
      }
    }
    case FIND_JOB_FOR_CANDIDATES: {
      return {
        ...state,
        findingJobForCandidates: true,
        findJobForCandidatesError: "",
      }
    }
    case FIND_JOB_FOR_CANDIDATES_COMPLETED: {
      return {
        ...state,
        findingJobForCandidates: false,
        jobForCandidates: action.payload,
      }
    }
    case FIND_JOB_FOR_CANDIDATES_FAILED: {
      const {message} = action.payload;
      return {
        ...state,
        findingJobForCandidates: false,
        findJobForCandidatesError: message,
      }
    }
    case SET_JOB_FOR_ASSESSMENT: {
      return {
        ...state,
        jobForAssessment: action.payload,
      };
    }
    case REMOVE_JOB_V2_POSTING: {
      return {
        ...state
      };
    }
    case REMOVE_JOB_V2_POSTING_COMPLETED: {
      const job = state.jobs.find(x => x.jobId === action.payload.jobId);
      if (job.draft) {
        return {
          ...state,
          jobs: state.jobs.filter(x => x.jobId !== action.payload.jobId),
          itemsCount: state.itemsCount - 1
        };
      } else {
        return {
          ...state,
          jobs: state.jobs.map(x => {
            if (x.jobId === action.payload.jobId) {
              x.isDeleted = true;
            }
            return x;
          })
        };
      }
    }
    case REMOVE_JOB_V2_POSTING_FAILED: {
      if (
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      ) {
        if (Array.isArray(action.payload.response.data.message)) {
          toast.error(
            JSON.stringify(
              action.payload.response.data.message.reduce((errors, error) => {
                errors[error.field] = error.message;
                return errors;
              }, {})
            )
          );
        } else {
          toast.error(action.payload.response.data.message);
        }
      } else {
        toast.error(action.payload.message);
      }
      return {
        ...state
      };
    }
    default:
      return state;
  }
}

export default jobsV2Reducer;
