import {PAGE_INIT} from "../actions/pages";
import {
  FIND_EMPLOYER_CITIES,
  FIND_EMPLOYER_CITIES_COMPLETED,
  FIND_EMPLOYER_CITIES_FAILED
} from "../actions/employerCities";
import {toast} from "react-toastify";

export const defaultState = Object.freeze({
  cities: [],
  findingCities: false,
  findingCitiesError: ''
});

const employerCitiesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return defaultState;
    }

    case FIND_EMPLOYER_CITIES: {
      return {
        ...state,
        findingCities: true,
        findingCitiesError: ''
      }
    }

    case FIND_EMPLOYER_CITIES_COMPLETED: {
      return {
        ...state,
        findingCities: false,
        cities: action.payload
      }
    }

    case FIND_EMPLOYER_CITIES_FAILED: {
      toast.error(
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.message
      );
      return {
        ...state,
        findingCities: false,
        findingCitiesError: action.payload.message
      }
    }

    default: {
      return state;
    }
  }
}

export default employerCitiesReducer;
