/*
* For display error processing from assessment API
*  */

function FeedbackErrorV1({errorName = 'Example name', errorMsg = 'any error message', className = ''}) {
  return (
    <div className={`fb-error-v1 ${className}`}>
      <p className='fb-error-v1__title'>Oh No!</p>
      <p className='fb-error-v1__txt'>
        {errorName} – {errorMsg}
      </p>
    </div>
  )
}

export default FeedbackErrorV1;
