import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import StartAIAssessmentCard from "../../common/Cards/StartAIAssessmentCard";
import * as Icon from "react-bootstrap-icons";
import { CircularProgressbar } from 'react-circular-progressbar';
import CandidatesGrid from "./CandidatesGrid";
import {useHistory, useLocation, useParams} from "react-router-dom";
import AiAssessmentModal from "../../common/AiAssessmentModal";
import useInitPage from "../../../hooks/useInitPage";
import AiAssessmentFeedbackModal from "../../common/AiAssessmentFeedbackModal";
import React, {useCallback, useEffect} from "react";
import {getDaysAgo} from "../../../utils/commons";
import {useDispatch, useSelector} from "react-redux";
import {findOneJobV2} from "../../../actions/jobsV2";
import RejectCandidateModal from "../../common/RejectCandidateModal";
import Auth from "../../../utils/auth";
import {findEmployer} from "../../../actions/employers";

const useFetchJobForCandidates = ({
  initialFetch = true,
  jobId = '',
}) => {
  const {
    jobForCandidates,
    findingJobForCandidates,
    findJobForCandidatesError,
  } = useSelector(state => state.jobsV2);
  const dispatch = useDispatch();

  const fetchOneJobV2 = useCallback(async () => {
    await dispatch(findOneJobV2({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    if(initialFetch) {
      fetchOneJobV2();
    }
  }, [fetchOneJobV2, initialFetch, jobId]);

  return {
    jobForCandidates,
    findingJobForCandidates,
    findJobForCandidatesError,
    fetchOneJobV2,
  }
}

const BackToDashboardButton = () => {
  const history = useHistory();
  const location = useLocation();
  const prevPageQueryString = location.state?.prevPageQueryString;
  const JOBS_PAGE_URL = prevPageQueryString ? `/employer/jobs?${prevPageQueryString}` : '/employer/jobs';

  const handleRedirectToJobs = () => {
    history.push(JOBS_PAGE_URL);
  }

  return (
    <Button className='btn-back' variant="outline-dark" onClick={handleRedirectToJobs}>
      <Icon.ArrowLeft size={20}/>
      <span>Back to Dashboard</span>
    </Button>
  )
}

const JobPostingDescription = () => {
  const {jobId} = useParams();

  const {
    jobForCandidates,
    findingJobForCandidates,
    findJobForCandidatesError,
    fetchOneJobV2,
  } = useFetchJobForCandidates({jobId});

  const history = useHistory();

  const handleRedirectToJobPostingPage = () => {
    history.push(`/job/details/${jobId}`);
  }

  if(findJobForCandidatesError) {
    return (
      <div className='job-posting-description-error'>
        <div>
          Failed to load job details. <br/> Please try again.
        </div>
        <Button variant="outline-danger" onClick={fetchOneJobV2}>
          Retry
        </Button>
      </div>
    )
  }

  if (findingJobForCandidates || !jobForCandidates) {
    return (
      <div className='job-posting-description-loading'>
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  const {jobTitle, city, country, postDate} = jobForCandidates;
  const postedDate = getDaysAgo(postDate)

  return (
    <div className='job-posting-description'>
      <h2 className='job-posting-description__title'>{jobTitle} – {city}, {country}</h2>
      <p className='job-posting-description__text'>
        <span className="job-posting-description__date">
          {postedDate}
        </span>
        <Button bsPrefix='button' variant='primary' onClick={handleRedirectToJobPostingPage}>
          <span style={{whiteSpace: 'nowrap'}}>View Job Posting</span>
        </Button>
      </p>
    </div>
  )
}

const progressStyles = {
  path: {
    stroke: `var(--color-primary)`,
  },
  trail: {
    stroke: '#FFEDDE',
  }
}

const AcceptanceRateCard = ({title = ''}) => {
  const {jobId} = useParams();

  const {
    jobForCandidates,
    findingJobForCandidates,
    findJobForCandidatesError,
    fetchOneJobV2,
  } = useFetchJobForCandidates({jobId, initialFetch: false}); // fetch saved job from Redux

  if(findJobForCandidatesError) {
    return (
      <div className='acceptance-card-error'>
        <div>
          Failed to load job details. <br/> Please try again.
        </div>
        <Button variant="outline-danger" onClick={fetchOneJobV2}>
          Retry
        </Button>
      </div>
    )
  }

  if (findingJobForCandidates || !jobForCandidates) {
    return (
      <div className='acceptance-card-loading'>
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  return (
    <div className="acceptance-card">
      <h3 className="acceptance-card__title">{title}</h3>
      <div className="acceptance-card__content">
        <div className='acceptance-card__progress'>
          <CircularProgressbar strokeWidth={10} styles={progressStyles} value={jobForCandidates.candidateAcceptanceRate}/>
        </div>
        <div className="acceptance-card__description">
          <p className="acceptance-card__rate">{jobForCandidates.candidateAcceptanceRate}%</p>
          <p className="acceptance-card__txt">Candidates Submitted</p>
          <p className="acceptance-card__sub">How do I improve my acceptance rate?</p>
        </div>
      </div>
    </div>
  )
}

function EmployerCandidatesPage() {
  const dispatch = useDispatch();

  const auth = Auth.getInstance();
  const userId = auth.getUserId();

  const employer = useSelector(state => state.employers.employer);
  const job = useSelector(state => state.jobsV2.jobForCandidates);

  useEffect(() => {
    (async () => {
       await dispatch(findEmployer({ employerId: userId }))
     })()
  }, [dispatch, userId]);

  // init state when page load
  useInitPage('CandidatesPage');
    return (
      <>
        <Container fluid className='ec-page'>
          <Row>
            <Col xs={3}>
              <div className='ec-sidebar'>
                <BackToDashboardButton />
                <div className="ec-sidebar__content">
                  <JobPostingDescription/>
                  <StartAIAssessmentCard/>
                  <AcceptanceRateCard title='Acceptance Rate' />
                </div>
              </div>
            </Col>
            <Col xs={9}>
              <div className='ec-content'>
                <CandidatesGrid employer={employer} job={job} />
              </div>
            </Col>
          </Row>
        </Container>

        <AiAssessmentModal />
        <AiAssessmentFeedbackModal />
        <RejectCandidateModal />
      </>
    );
}

export default EmployerCandidatesPage;
