import {hasMultipleResumeFiles, printProcessedAt} from "../../../utils/assessment";
import AssessmentSwitch from "./../AssessmentSwitch";
import FeedbackErrorV1 from "./../Feedback/FeedbackErrorV1";
import {AI_ASSESSMENT_STATUS, calculateColorByScore} from "../../../utils/commons";
import AssessmentResult from "./../AssessmentResult";
import React from "react";
import FeedbackErrorV2 from "./FeedbackErrorV2";

const FeedbackAssessment = ({acceptedPdfFiles}) => {
  if (hasMultipleResumeFiles(acceptedPdfFiles)) {
    return <AssessmentSwitch acceptedPdfFiles={acceptedPdfFiles} />;
  }

  return acceptedPdfFiles.map(item => {
    if(item.parsingError) {
      return (
        <FeedbackErrorV1
          key={item.path}
          errorName={`Error parsing file`}
          errorMsg={`'${item.path}'`}
        />
      );
    }

    if(item.status === AI_ASSESSMENT_STATUS.FAILED) {
      return (
        <FeedbackErrorV1
          key={item.path}
          errorName={item.errorName}
          errorMsg={item.errorMsg}
        />
      );
    }

    if(item.status === AI_ASSESSMENT_STATUS.DONE) {
      if(item.assessment?.resumeAssessment.length) {
        return (
          <AssessmentResult
            key={item.path}
            assessment={item.assessment.resumeAssessment}
            score={item.assessment.score}
            color={calculateColorByScore(item.assessment.score)}
            isScoreVisible={true}
          />
        );
      }

      return (
        <FeedbackErrorV2
          proccessedAt={printProcessedAt(item)}
          errorName='No Resume Assessment found'
          errorMsg='Please try again or contact with us'
        />
      );
    }

    return null;
  });
}

export default FeedbackAssessment;
