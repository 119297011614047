function IconSearch({width = 59, height = 59, className = '', color = '#F18200'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={width} height={height} className={className}>
      <path fill="none" d="M0 0h24v24H0z"/>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d="M11 19c4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8ZM21 21l-4.35-4.35"/>
    </svg>
  )
}

export default IconSearch;
