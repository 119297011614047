import React, { useCallback, useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import { toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import {MAX_FILES} from "../../utils/commons";

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


function FileDropZone(props = {}) {

  const onDropRejected = useCallback(rejectedFiles => {
    // console.log(rejectedFiles);
    rejectedFiles.forEach(item => {
      toast.error(`File '${item.file.path}' was rejected with error: ${item.errors[0].message}`);
    });
  }, []);

  // const onDrop = useCallback(acceptedFiles => {
  const onDropAccepted = useCallback(acceptedFiles => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      // console.log('reading file', file);

      reader.onabort = () => console.error(`'${file.path}': file reading was aborted`);
      reader.onerror = () => console.error(`'${file.path}': file reading has failed`);
      reader.onload = () => {
        // console.log(`'${file.path}': read OK`);

        const binaryStr = reader.result;
        // console.log(binaryStr);

        file.data = binaryStr;
        props.onAcceptedFile(file);
      }
      reader.readAsArrayBuffer(file);
    });

  }, [props]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: props.maxFiles || MAX_FILES,
    maxSize: 5 * 10 ** 6, /** 5Mb */
    noDragEventsBubbling: true,
    accept: {
      'application/pdf':  ['.pdf'],
    },
    // onDrop,
    onDropAccepted,
    onDropRejected,
  });

  const style = useMemo(() => ({
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div {...getRootProps({style})} className='drop-zone'>
      <input {...getInputProps()} />
      <Icon.FileEarmarkPlus size={93} color='var(--color-primary)' />
      <p className='drop-zone__title'>Drop Resume Here</p>
      <p className='drop-zone__sub'>({props.maxFiles || MAX_FILES} files max)</p>
    </div>
  );
}

export default FileDropZone;
