function IconQuestion({width = 59, height = 59, className = '', color = '#000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 59 59" className={className}>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
            d="M29.5 56.5834c14.9577 0 27.0834-12.1257 27.0834-27.0834S44.4577 2.41669 29.5 2.41669 2.41669 14.5423 2.41669 29.5 14.5423 56.5834 29.5 56.5834Z"/>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
            d="M21.6188 21.375a8.1248 8.1248 0 0 1 3.5478-4.3086 8.12498 8.12498 0 0 1 5.4904-1.0032 8.12526 8.12526 0 0 1 4.8422 2.7755 8.12576 8.12576 0 0 1 1.9092 5.2447c0 5.4166-8.125 8.125-8.125 8.125"/>
      <circle cx="29.2917" cy="42.8333" r="2.5" fill="#1E1E1E"/>
    </svg>
  )
}

export default IconQuestion;
