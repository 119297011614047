import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeFavoriteJobStatus, findJob,
} from "../../../actions/jobs";
import {
  Alert,
  Button,
  Col,
  Container, Modal,
  Row,
} from "react-bootstrap";
import moment from "moment";
import Auth from "../../../utils/auth";
import { downloadGeneratedPdf, pdfTypes } from "../../../utils/pdf"
import {toast} from "react-toastify";
import jobToDetails from "./jobToDetails";
import FileDropZone from "../../common/FileDropZone";
import ResumeAssessmentResults from "../../common/ResumeAssessmentResults";
import {AI_ASSESSMENT_STATUS, MAX_FILES} from "../../../utils/commons";
import parsePdfData from "../../../utils/pdfParser";
import {assessResume} from "../../../actions/resumes";
import {findLoggedRecruiter} from "../../../actions/recruiters";


const SalaryDisplay = ({ from, to }) => {
  if (from || to) {
    return ` $${from / 1000 || ''}k - $${to / 1000 || ''}k`;
  }
  return "-";
}

const EarningsDisplay = ({ referValue, feeValue, salaryFrom, isFeePercent, isReferrer }) => {
  if (salaryFrom) {
    if (isFeePercent) {
      if (isReferrer === true) {
        return `$${(referValue / 100 * salaryFrom).toLocaleString()}`;
      } else {
        return `$${(feeValue / 100 * salaryFrom).toLocaleString()}`;
      }
    } else {
      if (isReferrer === true) {
        return `$${referValue.toLocaleString()}`;
      } else {
        return `$${feeValue.toLocaleString()}`;
      }
    }
  }
  return "-";
}

class JobPostingPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    changeFavoriteJobStatus: PropTypes.func.isRequired,
    findJob: PropTypes.func.isRequired,
    findLoggedRecruiter: PropTypes.func.isRequired,
    loggedRecruiter: PropTypes.object.isRequired,
  };
  state = {
    jobDetails: null,
    showPdfAIAssessment: false,
    acceptedPdfFiles: [],
  };

  showPdfAIAssessment = () => {
    this.setState({ showPdfAIAssessment: true });
  }

  hidePdfAIAssessment = () => {
    this.setState({ showPdfAIAssessment: false, acceptedPdfFiles: []});
  }

  acceptedFileTitle = (file) => {
    return (
      <>
        <span className="assessment-file-name">{file.path}</span>
        - {file.size} bytes
        - {(!!file.parsingError)
        ? <span className="assessment-parsing-error">error parsing file</span>
        : 'file parsed OK - '}
        {file.status} {file.status === AI_ASSESSMENT_STATUS.IN_PROGRESS ? <div className="assessment-loader"></div> : '' }
      </>
    );
  }

  handleAcceptedFile = async (newFile) => {
    if (MAX_FILES <= this.state.acceptedPdfFiles.length) {
      console.error(`You can assess maximum ${MAX_FILES} files. Limit Exceeded`);
      toast.error(`You can assess maximum ${MAX_FILES} files. Limit Exceeded`);
    }
    else if (this.state.acceptedPdfFiles.find((file) => file.path === newFile.path)) {
      console.error(`File '${newFile.path}' already added for assessment`);
      toast.error(`File '${newFile.path}' already added for assessment`);
    }
    else {
      newFile.text = await parsePdfData(newFile.data);
      if (!newFile.text) {
        newFile.parsingError = true;
      }
      this.setState(
        {acceptedPdfFiles: this.state.acceptedPdfFiles ? [...this.state.acceptedPdfFiles, newFile] : [newFile]},
        async () => {
          if (!newFile.parsingError) {
            await this.assessResumeFile(newFile);
          }
        });
    }
  }

  assessResumeFile = async (file) => {
    await this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.IN_PROGRESS);

    let res;
    try {
      const {jobId} = this.props.job
      res = await assessResume(jobId, file.text, file.path);
      // console.log('assessResume.res: ', res);
    }
    catch (err) {
      toast.error(`Error assessing file '${file.path}': ${err.message}'`);
      this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.FAILED);
      return;
    }

    if (!res) {
      console.error('[assessResumeFile] empty response from assessResume');
      toast.error(`Error assessing file '${file.path}': empty server response'`);
      this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.FAILED);
      return;
    }

    this.setAcceptedFileStatus(file, AI_ASSESSMENT_STATUS.DONE);

    const idx = this.acceptedFileIndex(file);
    const acceptedPdfFiles = [...this.state.acceptedPdfFiles];
    acceptedPdfFiles[idx].assessment = res;
    this.setState({ acceptedPdfFiles }, () => {
      // console.log('[assessResumeFile]: in callback updated acceptedPdfFiles: ', this.state.acceptedPdfFiles);
    });
  }

  acceptedFileIndex = (file) => {
    const idx = this.state.acceptedPdfFiles.findIndex((f) => f.path === file.path);
    return idx === -1 ? undefined : idx;
  }

  setAcceptedFileStatus = async (file, status) => {
    const idx = this.acceptedFileIndex(file);
    // if (!idx) {
    //   console.log(`[handleAcceptedFile]: file.path (${file.path}) not found in this.state.acceptedPdfFiles`);
    // }
    const acceptedPdfFiles = [...this.state.acceptedPdfFiles];
    acceptedPdfFiles[idx].status = status;
    // console.log('[setAcceptedFileLoading] locally updated acceptedPdfFiles: ', acceptedPdfFiles);

    this.setState({ acceptedPdfFiles }, () => {
      // console.log('[setAcceptedFileStatus]: in callback updated acceptedPdfFiles: ', this.state.acceptedPdfFiles);
    });
  }

  async componentDidMount() {
    const {jobId} = this.props.match.params;

    await this.props.findJob({ jobId });
    this.setState({
      jobDetails: jobToDetails(this.props.job),
    });

    const auth = Auth.getInstance();
    const userId = auth.getUserId();
    this.props.findLoggedRecruiter({ recruiterId: userId });
  }

  _onJobDetailsHide = () => {
    this.props.history.goBack();
  }

  getJobDetails = (x, y) => {
    const { jobDetails } = this.state;
    return jobDetails ? jobDetails[x].body[y].content : "";
  };

  render() {
    const { loggedRecruiter } = this.props;
    const {jobDetails} = this.state;

    const auth = Auth.getInstance();
    const scope = auth.getScope();
    const blocked = auth.getIsBlocked();

    if(blocked) {
      return (
        <>
          <Container>
            <Row>
              <Col>
                <Alert variant="danger">
                  You are blocked to view job list, contact administrator
                  <a href="mailto:info@recruitnrefer.ca">info@recruitnrefer.ca</a>
                  to get more information
                </Alert>
              </Col>
            </Row>
          </Container>
        </>
      )
    }

    return (
      <>
        <Container fluid className="view-name-header">

          <div className="modal-header">
            <div
              className="modal-title h4">{`${jobDetails ? jobDetails[0].header : ""} | ${this.getJobDetails(6, 0)}`}</div>
          </div>

          <Row>
            <Col>
              <div className="job-desc-intro-box">
                <div className="intro-box-earnings-fav">
                  <h4 className="intro-box-recruiter-earnings">
                    <b>
                      {loggedRecruiter.referrer === true ? 'Referrer ' : 'Recruiter '}
                      Earnings:{" "}
                      <EarningsDisplay referValue={this.getJobDetails(8, 0).referValue}
                                       feeValue={this.getJobDetails(8, 0).feeValue}
                                       salaryFrom={this.getJobDetails(5, 0)}
                                       isFeePercent={this.getJobDetails(8, 0).isFeePercent}
                                       isReferrer={loggedRecruiter.referrer}/>
                    </b>
                  </h4>
                  <div className="intro-box-buttons">
                    {['admin', 'recruiter', 'referralpartner'].includes(scope) ? (
                      <Button
                        size="lg"
                        variant="info"
                        onClick={() => this.showPdfAIAssessment()}
                      >
                        <i className="material-icons">star</i>
                        Assess PDF Resume
                      </Button>
                    ) : null}

                    {/* <Button
                          className="assess-resume"
                          size="lg"
                          variant="info"
                          onClick={() => this.showAIAssessment()}
                        >
                          <i className="material-icons">star</i>
                          Assess Resume
                        </Button> */}
                    <Button
                      size="lg"
                      variant="primary"
                      onClick={() => this._changeFavoriteStatus(this.getJobDetails(8, 0))}
                    >
                      <i className="material-icons">favorite</i>
                      {this.getJobDetails(8, 0).favorite ? 'Unfavorite' : 'Favorite'}
                    </Button>
                  </div>
                </div>
                <hr/>
                <div className="intro-box-job-details">
                  <div className="intro-box-job-details-left">
                    <p className="company-name">{this.getJobDetails(6, 0)}</p>
                    <p className="job-name">{jobDetails ? jobDetails[0].header : ""}</p>
                    <p>
                      {`${this.getJobDetails(3, 0)} |
                              ${this.getJobDetails(4, 0)},
                              ${this.getJobDetails(4, 1)} |`}
                      <SalaryDisplay from={this.getJobDetails(5, 0)} to={this.getJobDetails(5, 1)}/>
                    </p>
                    <p className="post-date">{(moment().diff(this.getJobDetails(8, 0).postDate, 'days') < 25)
                      ? moment(this.getJobDetails(8, 0).postDate).fromNow()
                      : "30+ days ago"}</p>
                  </div>
                  <div className="intro-box-job-details-right">
                    <p className="num-of-pos-text">Number Of Positions: </p>
                    <p className="num-of-pos">x{this.getJobDetails(8, 0).numberOfPositions}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="job-desc-bottom-box">
                {(jobDetails || []).map((x, i) => {
                  if (i === 0) return null;
                  const arr = [];
                  arr.push(<p key={`header-${i}`} style={{fontWeight: 700, fontSize: '1.5em'}}>{x.header}</p>);
                  if (Array.isArray(x.body)) {
                    let j = 0;
                    for (const {header, content} of x.body) {
                      if (header === "Job") {
                        return arr;
                      } else if (header) {
                        arr.push(<p key={`sub-header-${i}-${j}`} style={{fontWeight: 700}}>{header}</p>);
                      }
                      arr.push(<p style={{whiteSpace: 'pre-line'}} key={`sub-content-${i}-${j}`}>{content || ' '}</p>);
                      j++;
                    }
                  } else if (typeof x.body === 'string') {
                    arr.push(
                      <div
                        key={x.id}
                        id={x.id}
                        dangerouslySetInnerHTML={{
                          __html: x.body,
                        }}
                      />
                    );
                  }
                  if (x.highlight) return <div style={{backgroundColor: x.highlight}}>{arr}</div>;
                  arr.push(<hr/>)
                  return arr;
                })}
              </div>
            </Col>
          </Row>


          <div className="modal-footer">
            <Button
              className="marign-right-10"
              variant="primary"
              onClick={() => this._onDownloadPDF(pdfTypes.recruiterPdf)}
            >
              Recruiter PDF
            </Button>

            <Button
              className="marign-right-10"
              variant="light"
              onClick={() => this._onDownloadPDF(pdfTypes.candidatePdf)}
            >
              Share with Candidate
            </Button>

            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onJobDetailsHide}
            >
              Close
            </Button>
          </div>

        </Container>

        <Modal
          show={this.state.showPdfAIAssessment}
          onHide={this.hidePdfAIAssessment}
        >
          <Modal.Header closeButton> <Modal.Title>Assess Your Candidates</Modal.Title> </Modal.Header>
          <Modal.Body>
            <FileDropZone maxFiles={MAX_FILES} onAcceptedFile={this.handleAcceptedFile} />
            {this.state.acceptedPdfFiles?.length ? (
                <>
                  <h6>Dropped files</h6>
                  <ul>
                    {this.state.acceptedPdfFiles.map(file => (
                      <li key={file.path} className="assessment-list-item">
                        {this.acceptedFileTitle(file)}
                        {/* {this.assessmentForFile(file)} */}
                        {Array.isArray(file.assessment?.resumeAssessment)
                          ? (<ResumeAssessmentResults assessment={file.assessment.resumeAssessment} />)
                          : null}
                      </li>))
                    }
                  </ul>
                </>
              )
              :null}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  _changeFavoriteStatus = async ({jobId, favorite}) => {
    try {
      await this.props.changeFavoriteJobStatus({jobId, favorite: !favorite});
    } catch (e) {
      console.error(e);
    }
  };

  _onDownloadPDF = async (pdfType) => {
    const {jobDetails } = this.state;
    // console.log('jobDetails: ', jobDetails);
    try {
      await downloadGeneratedPdf(jobDetails, pdfType);
    } catch (e) {
      console.error(e);
    }
  };

}

export default connect(
  (state) => ({
    loggedRecruiter: state.recruiters.loggedRecruiter,
    job: state.jobs.job,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        changeFavoriteJobStatus,
        findJob,
        findLoggedRecruiter,
      },
      dispatch
    )
)(JobPostingPage);
