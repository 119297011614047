import RnrModal from "./RnrModal";
import {useDispatch, useSelector} from "react-redux";
import {setModalRemoveJobPost} from "../../actions/modals";
import * as Icon from "react-bootstrap-icons";
import React from "react";
import {findJobsV2, removeJobV2Posting} from "../../actions/jobsV2";
import useQueryParams from "../../hooks/useQueryParams";

const RemoveJobPostModal = () => {
  const params = useQueryParams();
  const removeJobPostModal= useSelector(state => state.modals.removeJobPostModal);
  const dispatch = useDispatch();

  const handleHideModal = () => {
    dispatch(setModalRemoveJobPost({show: false, data: null}));
  }

  const handleRemoveJob = async () => {
    try {
      await dispatch(removeJobV2Posting(removeJobPostModal.data));
      await dispatch(findJobsV2(params));
    } catch (e) {
      console.error(`[handleRemoveJob]: cannot remove job post - ${e.message}`);
    } finally {
      handleHideModal();
    }
  }

  return (
    <RnrModal
      show={removeJobPostModal.show}
      onHide={handleHideModal}
      className='remove-job-modal'
    >
      <div className="remove-job-modal__content">
        <h3 className="remove-job-modal__title">
          <Icon.Trash size={16} className='icon-remove' /> Delete Job Post?
        </h3>
        <p className="remove-job-modal__txt">
          This action cannot be undone.
        </p>
        <div className="remove-job-modal__actions">
          {/* TODO: This buttons must be added as new type of button */}
          <button className="button-cancel" onClick={handleHideModal}>Cancel</button>
          <button className="button-accept" onClick={handleRemoveJob}>Delete</button>
        </div>
      </div>
    </RnrModal>
  )
}

export default RemoveJobPostModal;
