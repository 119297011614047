import {useLocation} from "react-router-dom";
import {useMemo} from "react";
import {parseQueryStr} from "../utils/url";

/**
 *
 * Hook to get query params from URL with parsed values
 * @param {Object} - Query parser config
 * @returns {Object} - Query params depend on parserConfig, e.g. {"limit": "20","offset": "0", "orders": ["postDate ASC"], "search": "", "status": ""}
 */
const useQueryParams = (parserConfig = {}) => {
  const {search} = useLocation();

  return useMemo(() => parseQueryStr(search, parserConfig), [search, parserConfig]);
}

export default useQueryParams;
