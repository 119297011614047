import {downloadResume, filterEmptyParams, openResumeInTab} from "../utils/commons";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import useQueryParams from "./useQueryParams";
import {useCallback, useEffect, useState} from "react";
import {createQueryStr} from "../utils/url";
import {useDispatch, useSelector} from "react-redux";
import {setModalAssessFeedbackShow} from "../actions/modals";
import {findCandidatesForJob} from "../actions/candidatesForJob";
import axios from "axios";
import auth from "../utils/auth";

const DEFAULT_PARAMS = Object.freeze({
  search: '',
  status: '',
  orders: [],
  limit: 20,
  offset: 0,
});
const IS_ENABLED_SINGLE_SORT_ORDER = true;

const transformParams = (defaultParams, queryParams) => {
  const params = {
    ...defaultParams,
    ...queryParams,
  };
  const {offset, limit, orders} = params;

  return {
    ...params,
    offset: parseInt(offset),
    limit: parseInt(limit),
    orders: Array.isArray(orders) ? orders : [orders],
  };
}

const initializeParams = (queryParams) => {
  return transformParams(DEFAULT_PARAMS, queryParams);
}

const createCandidateFetchParams = (jobId, queryParams) => {
  return {
    jobId,
    ...queryParams,
    orders: queryParams.orders.toString(),
  }
}

const getOnlyLastOrder = (orders) => {
  if(!orders || !orders.length) return null;

  return orders[orders.length - 1];
}

const hasMultipleOrders = (orders) => {
  if(!orders) return false;
  return orders && orders.length > 1;
}

const addSingleOrderToParams = (params) => {
  if(!params.orders) return params;

  if(hasMultipleOrders(params.orders)) {
    const orders = getOnlyLastOrder(params.orders);

    if(orders) {
      params.orders = [orders];
    }
  }

  return params;
}

const useFetchCandidatesForJob = ({
  isInitialFetch = true,
  jobId = '',
  offset = 0,
  limit = 20,
  search = '',
  status = '',
  orders = '',
}) => {
  const candidatesForJob = useSelector(state => state.candidatesForJob);
  const dispatch = useDispatch();

  const fetchCandidates = useCallback(async () => {
    const filteredParams = filterEmptyParams({
      jobId,
      offset,
      limit,
      search,
      status,
      orders,
    });

    await dispatch(findCandidatesForJob(filteredParams));
  }, [dispatch, jobId, offset, limit, search, status, orders]);

  useEffect(() => {
    if(isInitialFetch) {
      fetchCandidates();
    }
  }, [fetchCandidates, isInitialFetch]);

  return {
    candidatesForJob,
    fetchCandidates,
  }
}

const useCandidatesGrid = () => {
  const dispatch = useDispatch();
  const {jobId} = useParams();
  const history = useHistory();
  const params = useQueryParams();
  const [queryParams, setQueryParams] = useState(() => initializeParams(params));

  // sync grid state with URL
  useEffect(() => {
    history.replace({search: createQueryStr(queryParams), state: history.location.state});
  }, [queryParams, history]);

  const {candidatesForJob, fetchCandidates} = useFetchCandidatesForJob(createCandidateFetchParams(jobId, queryParams));

  const handleChangeQueryParams = (newParams) => {
    const params = IS_ENABLED_SINGLE_SORT_ORDER ?
      addSingleOrderToParams(newParams) :
      newParams;

    setQueryParams(prevParams => ({
      ...prevParams,
      ...params,
    }));
  };

  const handleShowFeedback = ({show = true, data = null}) => {
    dispatch(setModalAssessFeedbackShow({show, data}));
  }

  const handleOpenResume = async (e, candidate) => {
    e.preventDefault();
    try {
      await openResumeInTab(e, candidate)
    } catch (e) {
      toast.error(`Failed to open resume. ${e.message}`);
      console.error(`[handleOpenResume] - Error: ${e.message}`);
    }
  }

  const handleDownloadResume = async (e, candidate) => {
    e.preventDefault();
    try {
      await downloadResume(e, candidate);
    } catch (e) {
      toast.error(`Failed to download resume. ${e.message}`);
      console.error(`[handleDownloadResume] - Error: ${e.message}`);
    }
  }

  const handleRevertCandidate = async ({candidateId, recruiterId}) => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/revertCandidateStatus`, {
        candidateId,
        recruiterId
      }, {
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        }
      });
      await fetchCandidates();
      toast.success("Candidate status reverted successfully");
    } catch (e) {
      toast.error(`Failed to revert candidate. ${e.message}`);
      console.error(`[handleRevertCandidate] - Error:`, e);
    }
  }

  const handleRejectCandidateByEmployer = async ({candidateId}) => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/rejectCandidateByEmployer`, {
        candidateId,
      }, {
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        }
      });
      await fetchCandidates();
      toast.success("Candidate rejected successfully");
    } catch (e) {
      toast.error(`Failed to reject candidate. ${e.message}`);
      console.error('[handleRejectCandidateByEmployer] - Error:', e);
    }
  }

  const handleVerifyCandidate = async ({candidateId}) => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/verifyCandidate`, {
        candidateId,
      }, {
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        }
      });
      await fetchCandidates();
      toast.success("Candidate verified successfully");
    } catch (e) {
      toast.error(`Failed to verify candidate. ${e.message}`);
      console.error('[handleVerifyCandidate] - Error:', e);
    }
  }

  const handleAcceptCandidate = async ({candidateId}) => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/acceptCandidateByAdmin`, {
        candidateId,
      }, {
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        }
      });
      await fetchCandidates();
      toast.success("Candidate accepted successfully");
    } catch (e) {
      toast.error(`Failed to accept candidate. ${e.message}`);
      console.error('[handleAcceptCandidate] - Error:', e);
    }
  }

  const handleScheduleInterview = async ({candidate}) => {
    try {
      await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/inviteCandidateByEmployer`, {
        candidateId: candidate.candidateId,
        dateSlot1: (new Date()).toISOString(),
        dateSlot2: '',
        dateSlot3: '',
        note: '',
      }, {
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        }
      });
      await fetchCandidates();
      toast.success(
        "The interview request you made has been successful, your account manager from RNR will contact you once the interview has been confirmed"
      )
    } catch (e) {
      toast.error(`Failed to schedule interview. ${e.message}`);
      console.error('[handleScheduleInterview] - Error:', e);
    }
  }

  const handleEnvelopeClick = async ({candidate, job, employer}) => {
    const fullName = (candidate.firstName + ' ' + candidate.lastName).trim();
    const subject = encodeURIComponent(` Interview Request - ${fullName} - ${candidate.email}`);
    const body = encodeURIComponent(`Hi Rand\n\n${employer.companyName} would like to schedule an interview with candidate\n\n name: ${fullName} \n\n email: ${candidate.email} \n\n for the job: ${job.jobTitle} \n\n Can you please call us back to discuss further.\n\nThanks\n\n${(employer.firstName + ' ' + employer.lastName).trim()}`);
    const mailtoLink = `mailto:rand@recruitnrefer.com?subject=${subject}&body=${body}`;

    const a = document.createElement('a');
    a.href = mailtoLink;
    a.target = '_blank';
    a.click();
  }

  return {
    candidatesForJob,
    queryParams,
    handleChangeQueryParams,
    handleShowFeedback,
    handleDownloadResume,
    handleOpenResume,
    handleRevertCandidate,
    handleRejectCandidateByEmployer,
    handleVerifyCandidate,
    handleAcceptCandidate,
    handleScheduleInterview,
    handleEnvelopeClick,
  }
}

export default useCandidatesGrid;
