import {Button} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import React, {useMemo} from "react";
import {getDaysAgo} from "../../../utils/commons";

const SalaryDisplay = ({ from, to }) => {
  if (from || to) {
    return ` $${from / 1000 || ''}k - $${to / 1000 || ''}k`;
  }
  return "-";
}

const StatusDisplay = ({ job }) => {
  if (job.draft) return "Draft";
  if (job.isDeleted) return "Deleted";
  if (job.isExpired) return "Expired";
  return "Posted";
}

const StatusColor = ({ job }) => {
  if (job.draft) return 'var(--color-grey)';
  if (job.isDeleted) return 'var(--color-danger)';
  if (job.isExpired) return 'var(--color-warning)';
  return 'var(--color-success)';
}

const renderStatus = (job) => {
  return (
    <span>
      Status: <span className='grid-text--bold' style={{color: StatusColor({job})}}>{StatusDisplay({job})}</span>
    </span>
  )
}

const renderViewResumes = (job, onRedirectToCandidatesPage) => {
  if (job.draft) return null;
  return (
    <>
      <div className="grid-text-title">{job.noOfCandidates}</div>
      <div className="grid-text-sub">
        <span className='grid-link' onClick={() => onRedirectToCandidatesPage(job)}>View Resumes</span>
      </div>
    </>
  )
}

const renderAcceptanceRate = (job) => {
  if (job.draft) return null;
  return (
    <>
      <div className="grid-text-title">
        {job.candidateAcceptanceRate}%
      </div>
      <div className="grid-text-sub">Acceptance Rate</div>
    </>
  )
}

const GridRow = ({
  job,
  onRemoveJob,
  onRedirectToJobPage,
  onRedirectToJobPostingPage,
  onRedirectToCandidatesPage
}) => {
  const { jobTitle, city, country, postDate, salaryFrom, salaryTo, isDeleted} = job;
  const postedDate = useMemo(() => getDaysAgo(postDate), [postDate]);
  const salaryDisplay = useMemo(() => SalaryDisplay({ from: salaryFrom, to: salaryTo }), [salaryFrom, salaryTo]);
  const statusDisplay = useMemo(() => renderStatus(job), [job]);
  const location = city && country ? `${city}, ${country}` : '';

  return (
    <div className='jobs-grid-row'>
      <div className='jobs-grid-сol jobs-grid-сol-candidate'>
        <div className="grid-text-title-secondary">{jobTitle}</div>
        <div className='grid-text'>{location}</div>
        <div className='grid-text'>{postedDate}</div>
        <div className="grid-text">{statusDisplay}</div>
      </div>
      <div className='jobs-grid-сol jobs-grid-сol-resumes'>
        {renderViewResumes(job, onRedirectToCandidatesPage)}
      </div>
      <div className='jobs-grid-сol jobs-grid-сol-acceptance-rate'>
        {renderAcceptanceRate(job)}
      </div>
      <div className='jobs-grid-сol jobs-grid-сol-salary'>
        <div className="grid-text-title">{salaryDisplay}</div>
        <div className="grid-text-sub">Salary Range</div>
      </div>
      <div className='jobs-grid-сol jobs-grid-сol-actions'>
        <Button bsPrefix='button' className='button--outline' onClick={() => onRedirectToJobPage(job)}>Edit Job</Button>
        <Button bsPrefix='button' className='button--outline' onClick={() => onRedirectToJobPostingPage(job)}>View Job</Button>
        <Button disabled={isDeleted} style={{padding: '15px 10px'}} bsPrefix='button' className='button--transparent' onClick={() => onRemoveJob(job)}>
          <Icon.Trash size={16} className='icon-remove' />
        </Button>
      </div>
    </div>
  )
}

export default GridRow;
