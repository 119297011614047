import React, {forwardRef, useEffect, useState} from "react";
import {OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import {fetchResumeAssessments} from "../../../actions/candidates";
import {toast} from "react-toastify";

const useFetchShortAssessmentForCandidate = ({isFetchEnable = true, candidate}) => {
  const [assessment, setAssessment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const assessmentData = await fetchResumeAssessments(candidate.candidateId);

        if (!ignore) {
          const data = assessmentData.items[0];

          setAssessment(data);
        }
      } catch (err) {
        toast.error(`Failed fetching data: ${err.message}'`);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    if (!candidate?.jobId) return;
    let ignore = false;
    if(isFetchEnable) {
      fetchData();
    }
    return () => { ignore = true; };

  }, [candidate, isFetchEnable]);

  return {
    assessment,
    loading,
    error
  }
}

const AssessmentDetailsPopover = forwardRef(({title, content, ...props}, ref) => {
  return (
    <Popover ref={ref} id="popover-basic" bsPrefix='popover-assessment' {...props}>
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>
        {content}
      </Popover.Content>
    </Popover>
  );
})

const AssessmentDetailsDropdown = ({candidate, fullName, color}) => {
  const [show, setShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  let timer

  const handleMouseEnter = () => {
    if (timer) {
      clearTimeout(timer);
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    timer = setTimeout(() => {
      setIsHovered(false);
    }, 400);
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const {assessment, loading, error, } = useFetchShortAssessmentForCandidate({isFetchEnable: show, candidate});

  const getPopoverContent = () => {
    if (error) {
      return (
        <div style={{height: '62px', display: 'flex', alignItems: "center", justifyContent: 'center', gap: '10px', color: 'var(--color-danger)', fontSize: '20px'}}>
          <div>Failed to load data</div>
        </div>
      )
    }
    if (loading && !assessment) {
      return (
        <div style={{height: '62px', display: 'flex', alignItems: "center", justifyContent: 'center'}}>
          <Spinner animation="border" variant="primary" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }
    if (assessment?.errors?.length) {
      return (
        <div style={{height: '62px', display: 'flex', alignItems: "center", justifyContent: 'center', gap: '10px', fontSize: '14px'}}>
          <div style={{color: 'var(--color-danger)'}}>{assessment.errors[assessment.errors.length - 1]}</div>
        </div>
      )
    }
    if (!assessment?.resumeAssessment.length) {
      return (
        <div style={{height: '62px', display: 'flex', alignItems: "center", justifyContent: 'center', gap: '10px', fontSize: '14px'}}>
          <div>No Results Found</div>
        </div>
      )
    }

    return (
      <ul className="assessment-result__list assessment-result__list--dropdown">
        {assessment.resumeAssessment.map(item => (
          <li className="assessment-result__item" key={item.requirement}>
            <div className="assessment-result__feedback">
              <span className="assessment-result__status" style={{background: item.status}}></span>
              <span>{item.feedback}</span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='left'
      show={show || isHovered}
      delay={{show: 250, hide: 400}}
      rootClose={false}
      onToggle={(_show) => setShow(_show)}
      overlay={
        <AssessmentDetailsPopover
          title={fullName}
          content={getPopoverContent()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
    }
    >
      {({ref, ...triggerHandler}) => (
        <Icon.InfoCircle ref={ref} size={16} style={{fill: color}} {...triggerHandler} />
      )}
    </OverlayTrigger>
  );
};


export default AssessmentDetailsDropdown;
