function IconAddNewJob({width = 45, height = 45, className = '', color = '#000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 45 45" className={className}>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
            d="M22.1526 43.0486c11.3909 0 20.625-9.2341 20.625-20.625 0-11.3908-9.2341-20.62495-20.625-20.62495-11.3908 0-20.62495 9.23415-20.62495 20.62495 0 11.3909 9.23415 20.625 20.62495 20.625ZM22.1526 11.1736v22.5M33.4026 22.4236h-22.5"/>
    </svg>
  )
}

export default IconAddNewJob;
