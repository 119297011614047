import queryString from "query-string";

export const createQueryStr = (queryParams, parserConfig = {}) => {
  const options = {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
    ...parserConfig
  };

  return queryString.stringify(queryParams, options);
}

export const parseQueryStr = (queryStr, parserConfig = {}) => {
  const options = {
    arrayFormat: 'comma',
    ...parserConfig
  };

  return queryString.parse(queryStr, options);
}
