/*
* For display empty response from assessment API
*  */

function FeedbackErrorV2({
  errorName = 'No resume assessment found',
  errorMsg = '',
  className = ''
}) {
  return (
    <div className={`fb-error-v2 ${className}`}>
      <p className='fb-error-v2__title'>{errorName}</p>
      {errorMsg && <p className='fb-error-v2__text'>{errorMsg}</p>}
      {/* TODO: add relevant link on faq/support */}
      <a href='https://inforecruitnrefer.ca/contact-us' className='fb-error-v2__link'>What to do next</a>
    </div>
  )
}

export default FeedbackErrorV2;
