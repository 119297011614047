import BaseCard from "./BaseCard";
import IconTermsAndConditions from "../Icons/IconTermsAndConditions";

function TermsAndConditions() {
  return (
    <BaseCard
      variant='light'
      icon={<IconTermsAndConditions />}
      title='Terms and Conditions'
      content='Click to view your most updated RNR contract'
    />
  )
}

export default TermsAndConditions;
