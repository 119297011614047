/*
* Description: display contact information in assignment feedback
* Usage:
  data: [
    {value: 'assurance_manager', label: 'Assurance Manager' },
    { value: 'tax_senior', label: 'Tax Senior' },
    { value: 'tax_manager', label: 'Tax Manager' },
  ]
* */

function FeedbackContacts({data = []}) {
  return (
    <div className='feedback-contacts'>
      {data.map((item) => {
        return (
          <div className="fb-item" key={item.label}>
            <div className="fb-item__label">{item.label}</div>
            <div className="fb-item__txt">{item.value}</div>
          </div>
        )
      })}
    </div>
  )
}

export default FeedbackContacts;
