import {returnTextForArrayValue} from "../../../utils/commons";
import jobFunctions from "../../../data/jobFunctions";
import jobTypes from "../../../data/jobTypes";
import states from "../../../data/states";
import countries from "../../../data/countries";
import industries from "../../../data/industries";

const jobToDetails = (job) => [
  {
    header: job.jobTitle,
  },
  {
    header: 'Job Highlights:',
    body: [
      {
        header: "Skills - Must Have:",
        content: job.mustHave,
      },
      {
        header: "Ideal Companies to Recruit From:",
        content: job.skillsConsideredBonus,
      },
      {
        header: "Hiring Status:",
        content: job.commissionPayment,
      },
    ]
  },
  {
    header: 'About this Job:',
    body: job.jobDescription,
    id: 'recruiter-home-page-job-description'
  },
  {
    header: '',
    body: [
      {
        header: "Job Function:",
        content: returnTextForArrayValue(jobFunctions, job.jobFunction),
      },
      {
        header: "Reporting To:",
        content: job.reportingTo,
      },
      {
        header: "Job Type:",
        content: returnTextForArrayValue(jobTypes, job.jobType),
      },
      {
        header: "Team Size To Manage:",
        content: job.teamSizeToManage,
      },
    ]
  },
  {
    header: 'Job Location:',
    body: [
      {
        header: "City:",
        content: job.city,
      },
      {
        header: "Province/State:",
        content: returnTextForArrayValue(states, job.state),
      },
      {
        header: "Country:",
        content: returnTextForArrayValue(countries, job.country),
      },
      {
        header: "Location Type:",
        content: job.locationType,
      },
      {
        header: "Other Location:",
        content: job.otherLocation,
      },
      {
        header: "Candidate Relocation:",
        content: job.candidateRelocation,
      },
    ]
  },
  {
    header: 'Compensation:',
    body: [
      {
        header: "Salary From:",
        content: job.salaryFrom,
      },
      {
        header: "Salary To:",
        content: job.salaryTo,
      },
      {
        header: "Bonus Payment:",
        content: job.bonusPayment,
      },
    ]
  },
  {
    header: 'About this Company:',
    body: [
      {
        header: "Company Name:",
        content: job.companyName,
      },
      {
        header: "Industry:",
        content: returnTextForArrayValue(industries, job.industry),
      },
      {
        header: "Company Description:",
        content: job.companyDescription,
      },
    ]
  },
  {
    header: 'Culture, Perks and Benefits:',
    body: job.culturePerksAndBenefits,
    id: 'recruiter-home-page-CPB-description'
  },
  {
    header: '',
    body: [
      {
        header: "Job",
        content: job,
      },
    ]
  },
];

export default jobToDetails;
