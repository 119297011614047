import {createContext, useMemo, useState, useContext} from "react";

/*
  Context to store the active accepted pdf file
  for working with multiple uploaded resumes in AI Assessment Modal(switch between tabs)
*/

const ActiveAssessmentPdfFileContext = createContext(null);
ActiveAssessmentPdfFileContext.displayName = 'ActiveAcceptedPdfFileContext';

export const useActiveAssessmentPdfFile = () => {
  const context = useContext(ActiveAssessmentPdfFileContext);
  if (!context) {
    throw new Error('useActiveAssessmentPdfFile must be used within a ActiveAssessmentPdfFileProvider');
  }
  return context;
}

const ActiveAssessmentPdfFileProvider = ({children}) => {
  const [activeAcceptedPdfFile, setActiveAcceptedPdfFile] = useState(null);

  const activeAcceptedPdfFileObj = useMemo(() => {
    return {
      activeAcceptedPdfFile,
      setActiveAcceptedPdfFile
    }
  }, [activeAcceptedPdfFile, setActiveAcceptedPdfFile]);

  return (
    <ActiveAssessmentPdfFileContext.Provider value={activeAcceptedPdfFileObj}>
      {children}
    </ActiveAssessmentPdfFileContext.Provider>
  )
}

export default ActiveAssessmentPdfFileProvider;
