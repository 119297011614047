import {useCallback, useState} from "react";
import {toast} from "react-toastify";
import {AI_ASSESSMENT_STATUS, MAX_FILES} from "../utils/commons";
import {assessResume} from "../actions/resumes";
import parsePdfData from "../utils/pdfParser";

const useAssessmentResumes = () => {
  const [acceptedPdfFiles, setAcceptedPdfFiles] = useState([]);

  const hidePdfAIAssessment = () => {
    setAcceptedPdfFiles([]);
  }

  const acceptedFileIndex = (file) => {
    const idx = acceptedPdfFiles.findIndex((f) => f.path === file.path);
    return idx === -1 ? undefined : idx;
  }

  const setAcceptedFilePayload = (file, payload = {}) => {
    const idx = acceptedFileIndex(file);
    const updatedAcceptedPdfFiles = [...acceptedPdfFiles];
    Object.assign(updatedAcceptedPdfFiles[idx], payload);

    setAcceptedPdfFiles([...updatedAcceptedPdfFiles]);
  }

  const assessResumeFile = async (file, job) => {
    setAcceptedFilePayload(file, {status: AI_ASSESSMENT_STATUS.IN_PROGRESS});

    let res;
    try {
      res = await assessResume(job.jobId, file.text, file.path);
    }
    catch (err) {
      toast.error(`Error assessing file '${file.path}': ${err.message}'`);
      setAcceptedFilePayload(file, {
        status: AI_ASSESSMENT_STATUS.FAILED,
        errorName: `Error assessing file`,
        errorMsg: `'${file.path}': ${err.message}'`,
        processedAt: Date.now(),
        job: JSON.stringify(job),
      });
      return;
    }

    if (!res) {
      console.error('[assessResumeFile] empty response from assessResume');
      toast.error(`Error assessing file '${file.path}': empty server response'`);
      setAcceptedFilePayload(file, {
        status: AI_ASSESSMENT_STATUS.FAILED,
        errorName: `Error assessing file`,
        errorMsg: `'${file.path}': empty server response'`,
        processedAt: Date.now(),
        job: JSON.stringify(job),
      });
      return;
    }

    setAcceptedFilePayload(file, {
      status: AI_ASSESSMENT_STATUS.DONE,
      assessment: res,
      processedAt: Date.now(),
      job: JSON.stringify(job),
    });
  }

  const handleAssessResume = useCallback(async (job, isforceReasign) => {
    if(!job) {
      new Error('Job is required to assess resume');
    }

    if(isforceReasign) {
      await Promise.all(acceptedPdfFiles.map((file) => assessResumeFile(file, job)));
      return
    }

    const filesToAssess = acceptedPdfFiles.filter((file) => !file.status);
    await Promise.all(filesToAssess.map((file) => assessResumeFile(file, job)));
  }, [acceptedPdfFiles]);

  const handleAcceptedFile = useCallback(async (newFile) => {
    if (MAX_FILES <= acceptedPdfFiles.length) {
      console.error(`You can assess maximum ${MAX_FILES} files. Limit Exceeded`);
      toast.error(`You can assess maximum ${MAX_FILES} files. Limit Exceeded`);
    }
    else if (acceptedPdfFiles.find((file) => file.path === newFile.path)) {
      console.error(`File '${newFile.path}' already added for assessment`);
      toast.error(`File '${newFile.path}' already added for assessment`);
    }
    else {
      newFile.text = await parsePdfData(newFile.data);
      if (!newFile.text) {
        newFile.parsingError = true;
      }
      setAcceptedPdfFiles((prevState) => [...prevState, newFile]);
    }
  }, [acceptedPdfFiles]);

  return {
    acceptedPdfFiles,
    handleAcceptedFile,
    handleAssessResume,
    hidePdfAIAssessment,
  }
}

export default useAssessmentResumes;
