import {command, find, findOne} from "../utils/api";

const JOBS_V1_BASE_URL = `${process.env.PUBLIC_URL}/api/v1/jobs`;
const JOBS_V2_BASE_URL = `${process.env.PUBLIC_URL}/api/v2/jobs/find`;

export const FIND_JOBS_V2 = "FIND_JOBS_V2";
export const FIND_JOBS_V2_COMPLETED = "FIND_JOBS_V2_COMPLETED";
export const FIND_JOBS_V2_FAILED = "FIND_JOBS_V2_FAILED";

export const findJobsV2 = find(
  JOBS_V2_BASE_URL,
  FIND_JOBS_V2,
  FIND_JOBS_V2_COMPLETED,
  FIND_JOBS_V2_FAILED
);

export const FIND_JOB_FOR_CANDIDATES = "FIND_JOB_FOR_CANDIDATES";
export const FIND_JOB_FOR_CANDIDATES_COMPLETED = "FIND_JOB_FOR_CANDIDATES_COMPLETED";
export const FIND_JOB_FOR_CANDIDATES_FAILED = "FIND_JOB_FOR_CANDIDATES_FAILED";

export const findOneJobV2 = findOne(
  JOBS_V2_BASE_URL,
  FIND_JOB_FOR_CANDIDATES,
  FIND_JOB_FOR_CANDIDATES_COMPLETED,
  FIND_JOB_FOR_CANDIDATES_FAILED
)

export const SET_JOB_FOR_ASSESSMENT = 'SET_JOB_FOR_ASSESSMENT';

export const setJobForAssessment = (job) => {
  return {
    type: SET_JOB_FOR_ASSESSMENT,
    payload: job
  }
}

export const REMOVE_JOB_V2_POSTING = "REMOVE_JOB_V2_POSTING";
export const REMOVE_JOB_V2_POSTING_COMPLETED = "REMOVE_JOB_V2_POSTING_COMPLETED";
export const REMOVE_JOB_V2_POSTING_FAILED = "REMOVE_JOB_V2_POSTING_FAILED";

// TODO: replace V1 api URL with V2, when V2 will be implemented
export const removeJobV2Posting = command(
  JOBS_V1_BASE_URL + "/removeJobPosting",
  REMOVE_JOB_V2_POSTING,
  REMOVE_JOB_V2_POSTING_COMPLETED,
  REMOVE_JOB_V2_POSTING_FAILED
);
