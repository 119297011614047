import {Col, Dropdown, DropdownButton, Form, FormControl, InputGroup} from "react-bootstrap";
import IconSearch from "../../common/Icons/IconSearch";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {debounce, JOBS_FILTER_STATUS} from "../../../utils/commons";
import {useDispatch, useSelector} from "react-redux";
import {findEmployerCities} from "../../../actions/employerCities";

const getDropdownActiveItem = (items, activeItem, defaultValue) => {
  if (activeItem === null) return defaultValue;
  if (typeof activeItem === 'string') {
    return items.find(item => item === activeItem) ?? defaultValue;
  }
  const idx = items.findIndex(item => item.value === activeItem);
  return items[idx]?.label ?? defaultValue;
}

const getDropdownItem = (item) => {
  const key = typeof item === 'string' ? item : item.value;
  const label = typeof item === 'string' ? item : item.label;

  return { key, label };
}

const DropdownFilter = ({
  title = 'City',
  activeItem = null,
  items = ['ALL', 'Calgary', 'Toronto', 'Vancouver'],
  onSelect = () => {}
}) => {
  const selectedItem = useMemo(() => getDropdownActiveItem(items, activeItem, title), [activeItem, items, title]);

  return (
    <DropdownButton
      className='filterbar-dropdown'
      title={selectedItem}
    >
      {items.map(item => {
        const {key, label} = getDropdownItem(item);
        return (
          <Dropdown.Item key={key} as="button" onSelect={() => onSelect(item)}>
            {label}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}

const STATUS_OPTIONS = Object.freeze([
  { value: JOBS_FILTER_STATUS.NO_ORDER, label: 'ALL' },
  { value: JOBS_FILTER_STATUS.EXPIRED, label: 'Expired' },
  { value: JOBS_FILTER_STATUS.DRAFT, label: 'Draft' },
  { value: JOBS_FILTER_STATUS.POSTED, label: 'Posted' },
]);

const initializeState = (queryParams) => ({
  search: queryParams.search || '',
  byStatus: queryParams.byStatus || JOBS_FILTER_STATUS.NO_ORDER,
  city: queryParams.city || '',
});

const handleStatusChange = (setStatus, onChange) => (selectedOption) => {
  const isAll = selectedOption?.value === JOBS_FILTER_STATUS.NO_ORDER;
  const newStatus = isAll ? null : selectedOption?.value;
  setStatus(newStatus);
  onChange({ byStatus: newStatus, offset: 0 });
};

const handleCityChange = (setCity, onChange) => (city) => {
  const isAll = city === 'ALL';
  const newCity = isAll ? '' : city;
  setCity(newCity);
  onChange({ city: newCity, offset: 0 });
};

const handleSearch = (setSearch, debouncedHandleSearch) => (e) => {
  const newSearch = e.target.value;
  setSearch(newSearch);
  debouncedHandleSearch({ search: newSearch, offset: 0 });
};

const useFetchEmployerCities = () => {
  const employerCities = useSelector(state => state.employerCities);
  const dispatch = useDispatch();

  const fetchEmployerCities = useCallback(async () => {
    await dispatch(findEmployerCities());
  }, [dispatch])

  useEffect(() => {
    fetchEmployerCities();
  }, [findEmployerCities]);

  return {
    employerCities,
    fetchEmployerCities
  }
};

const FilterBar = ({queryParams, onChange}) => {
  const [{ search, city, byStatus }, setState] = useState(() => initializeState(queryParams));
  const setStatus = (byStatus) => setState((prevState) => ({ ...prevState, byStatus }));
  const setSearch = (search) => setState((prevState) => ({ ...prevState, search }));
  const setCity = (city) => setState((prevState) => ({ ...prevState, city }));


  const debouncedHandleSearch = useCallback(debounce(onChange), [onChange]);
  const memoizedHandleStatusChange = useCallback(handleStatusChange(setStatus, onChange), [setStatus, onChange]);
  const memoizedHandleCityChange = useCallback(handleCityChange(setCity, onChange), [setCity, onChange]);
  const memoizedHandleSearch = useCallback(handleSearch(setSearch, debouncedHandleSearch), [setSearch, debouncedHandleSearch]);

  const {employerCities} = useFetchEmployerCities();
  const citiesWithoutEmptyValue = employerCities.cities.filter(Boolean);
  const citiesWithAll = ['ALL', ...citiesWithoutEmptyValue];

  const handleForm = (e) => {
    e.preventDefault();
  }

  return (
    <Form onSubmit={handleForm} className='jobs-filter-bar'>
      <div className='jobs-filter-bar__row'>
        <InputGroup className='fb-search'>
          <InputGroup.Prepend>
            <InputGroup.Text color='red' id="search-icon"><IconSearch width={24} height={24}/></InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type='search'
            id="search"
            size='sm'
            placeholder="Search for My Jobs"
            aria-label="Search"
            aria-describedby="search-icon"
            value={search}
            onChange={memoizedHandleSearch}
          />
        </InputGroup>

        <div className='jobs-filter-bar__dropdowns'>
          <DropdownFilter
            title='City'
            activeItem={city}
            items={citiesWithAll}
            onSelect={memoizedHandleCityChange}
          />

          <DropdownFilter
            title='Status'
            activeItem={byStatus}
            items={STATUS_OPTIONS}
            onSelect={memoizedHandleStatusChange}
          />
        </div>
      </div>
    </Form>
  )
}

export default FilterBar;
