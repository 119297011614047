import {useDispatch} from "react-redux";
import {setModalRejectCandidate} from "../../../actions/modals";
import {Badge, Dropdown} from "react-bootstrap";

const getStatusByValue = (value) => {
  const reverted = Object.entries(Status).map(([key, val]) => [val, key]);
  const revertedStatuses = Object.fromEntries(reverted);
  return revertedStatuses[value];
}

const Status = Object.freeze({
  // Statuses that will be used in Employer Dashboard V2
  // These statuses will be visible to admin, recruiter and referralpartner
  PendingCandidateConsent: 'PendingCandidateConsent',
  AwaitingAdminApproval: 'AwaitingAdminApproval',
  RejectedByAdmin: 'RejectedByAdmin',
  // Statuses of Employer Dashboard V1
  ApprovedByAdmin: 'ApprovedByAdmin',
  RejectedByEmployer: 'RejectedByEmployer',
  InterviewRequested: 'InterviewRequested',
  Undefined: 'Undefined',
});


const AdminShortStatus = Object.freeze({
  PendingCandidateConsent: 'Consent Pending',
  AwaitingAdminApproval: 'Awaiting Admin',
  RejectedByAdmin: 'Rejected Admin',
  ApprovedByAdmin: 'Approved Admin',
  RejectedByEmployer: 'Rejected Empl',
  InterviewRequested: 'Interview Req',
  Undefined: 'Undefined'
});

const EmployerShortStatus = Object.freeze({
  PendingCandidateConsent: 'Consent Pending',
  AwaitingAdminApproval: 'Awaiting',
  RejectedByAdmin: 'Rejected',
  ApprovedByAdmin: 'Pending',
  RejectedByEmployer: 'Rejected',
  InterviewRequested: 'Approved',
  Undefined: 'Undefined'
});


const AdminStatusVariants = Object.freeze({
  PendingCandidateConsent: 'warning',
  AwaitingAdminApproval: 'info',
  RejectedByAdmin: 'danger',
  ApprovedByAdmin: 'success',
  RejectedByEmployer: 'danger',
  InterviewRequested: 'info',
  Undefined: 'secondary',
});

const EmployerStatusVariants = Object.freeze({
  PendingCandidateConsent: 'warning',
  AwaitingAdminApproval: 'info',
  RejectedByAdmin: 'danger',
  ApprovedByAdmin: 'warning',
  RejectedByEmployer: 'danger',
  InterviewRequested: 'success',
  Undefined: 'secondary',
});


const AdminStatuses = Object.freeze({
  ApprovedByAdmin: 'Approved by Admin',
  InterviewRequested: 'Interview Requested',
  RejectedByEmployer: 'Rejected by Employer',
  RejectedByAdmin: 'Rejected by Admin',
  AwaitingAdminApproval: 'Awaiting Admin Approval',
  PendingCandidateConsent: 'Pending Candidate Consent',
});

const EmployerStatuses = Object.freeze({
  ApprovedByAdmin: 'Pending',
  RejectedByEmployer: 'Rejected',
  InterviewRequested: 'Approved',
});


const StatusBadge = ({status, statusVariants, shortStatuses, ...props}) => {
  const statusKey = getStatusByValue(status);
  const variant = statusVariants[statusKey] //StatusVariant[statusKey];
  const shortStatus = shortStatuses[statusKey];

  return (
    <Badge variant={variant} {...props}>{shortStatus}</Badge>
  )
}

const adminAction = ({status, activeStatus}) => {
  switch (true) {
    case status === Status.PendingCandidateConsent && activeStatus === Status.AwaitingAdminApproval:
      return 'verifyCandidate';
    case status === Status.AwaitingAdminApproval && activeStatus === Status.ApprovedByAdmin:
      return 'acceptCandidate';
    case status === Status.ApprovedByAdmin && activeStatus === Status.RejectedByAdmin:
    case status === Status.RejectedByAdmin && activeStatus === Status.ApprovedByAdmin:
      return 'revertCandidate';
    case status === Status.ApprovedByAdmin && activeStatus === Status.InterviewRequested:
      return 'scheduleInterview';
    case status === Status.InterviewRequested && activeStatus === Status.RejectedByEmployer:
      return 'rejectCandidateByEmployer';
    // must be last, because not include status
    case activeStatus === Status.RejectedByAdmin:
      return 'rejectCandidateByAdmin';
    default:
      return null;
  }
}

const employerAction = ({status, activeStatus}) => {
  switch (true) {
    case status === Status.ApprovedByAdmin && activeStatus === Status.InterviewRequested:
      return 'scheduleInterview';
    case (status === Status.ApprovedByAdmin && activeStatus === Status.RejectedByEmployer):
    case (status === Status.InterviewRequested && activeStatus === Status.RejectedByEmployer):
      return 'rejectCandidateByEmployer';
    default:
      return null;
  }
}

const StatusDropdown = ({
  status,
  scope,
  candidate,
  onRevertCandidate,
  onRejectCandidateByEmployer,
  onVerifyCandidate,
  onAcceptCandidate,
  onScheduleInterview,
}) => {
  const dispatch = useDispatch();

  const adminActions = {
    verifyCandidate: ({candidate}) => {
      onVerifyCandidate({candidateId: candidate.candidateId});
    },
    acceptCandidate: ({candidate}) => {
      onAcceptCandidate({candidateId: candidate.candidateId});
    },
    revertCandidate: ({candidate}) => {
      onRevertCandidate({candidateId: candidate.candidateId, recruiterId: candidate.recruiterId});
    },
    scheduleInterview: ({candidate}) => {
      onScheduleInterview({candidate});
    },
    rejectCandidateByEmployer: ({candidate}) => {
      onRejectCandidateByEmployer({candidateId: candidate.candidateId});
    },
    rejectCandidateByAdmin: ({candidate}) => {
      dispatch(setModalRejectCandidate({show: true, data: candidate}));
    },
  }

  const employerActions = {
    scheduleInterview: ({candidate}) => {
      onScheduleInterview({candidate});
    },
    rejectCandidateByEmployer: ({candidate}) => {
      onRejectCandidateByEmployer({candidateId: candidate.candidateId});
    },
  }

  const handleChangeStatus = async ({activeStatus}) => {
    console.log('activeStatus: ', activeStatus);
    
    const statusActionName = scope === 'admin' ? adminAction({status, activeStatus}) : employerAction({status, activeStatus});

    console.log(statusActionName, 'statusActionName');

    if (statusActionName) {
      const actions = scope === 'admin' ? adminActions : employerActions;
      actions[statusActionName]({candidate});
    }
  }

  const isItemDisabled = ({status, activeStatus}) => {
    if (scope === 'admin') {
      return !adminAction({status, activeStatus});
    } else if (scope === 'employer') {
      return !employerAction({status, activeStatus});
    }
    return false;
  }

  const statuses = scope === 'admin' ? AdminStatuses : EmployerStatuses;
  const statusVariants = scope === 'admin' ? AdminStatusVariants : EmployerStatusVariants;
  const shortStatuses = scope === 'admin' ? AdminShortStatus : EmployerShortStatus;

  Object.entries(statuses).forEach(([key, value]) => console.log(`${key} : ${value}`));

  return (
    <Dropdown>
      <Dropdown.Toggle childBsPrefix='status-dropdown' variant="primary" id="dropdown-basic">
        <StatusBadge status={status} statusVariants={statusVariants} shortStatuses={shortStatuses} style={{padding: '12px 18px', width: '150px'}} title={status} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.entries(statuses).map(([key, value]) => (
          <Dropdown.Item disabled={isItemDisabled({status, activeStatus: key})} key={key} onClick={() => handleChangeStatus({activeStatus: key})}>
            {shortStatuses[key]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default StatusDropdown;
