import moment from "moment/moment";
import React from "react";
import FeedbackDetails from "./Feedback/FeedbackDetails";

const getAssessmentQueueDetails = (assessmentQueue, duration) => {
  if(!assessmentQueue) return null;

  const details = [];

  details.push({label: 'assessmentId', value: assessmentQueue?.assessmentId});
  details.push({label: 'createdAt', value: assessmentQueue?.createdAt});
  details.push({label: 'Time passed', value: duration});

  return details;
}

const AssessmentQueue = ({assessmentQueue}) => {
  if(assessmentQueue) return null;

  const duration = moment.duration(moment().diff(assessmentQueue?.createdAt)).humanize();
  const details = getAssessmentQueueDetails(assessmentQueue, duration);

  return (
    <>
      <FeedbackDetails
       title='Resume Assessment Still in Processing Queue'
       data={details}
      />
    </>
  );
}

export default AssessmentQueue;
