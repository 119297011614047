import {find} from "../utils/api";

const CANDIDATES_FOR_JOB_BASE_URL = `${process.env.PUBLIC_URL}/api/v1/candidates/find`;

export const FIND_CANDIDATES_FOR_JOB = "FIND_CANDIDATES_FOR_JOB";
export const FIND_CANDIDATES_FOR_JOB_COMPLETED = "FIND_CANDIDATES_FOR_JOB_COMPLETED";
export const FIND_CANDIDATES_FOR_JOB_FAILED = "FIND_CANDIDATES_FOR_JOB_FAILED";

export const findCandidatesForJob = find(
  CANDIDATES_FOR_JOB_BASE_URL,
  FIND_CANDIDATES_FOR_JOB,
  FIND_CANDIDATES_FOR_JOB_COMPLETED,
  FIND_CANDIDATES_FOR_JOB_FAILED
);
