import {useHistory} from "react-router-dom";
import useQueryParams from "./useQueryParams";
import {useCallback, useEffect, useState} from "react";
import {createQueryStr} from "../utils/url";
import {useDispatch, useSelector} from "react-redux";
import {setModalRemoveJobPost} from "../actions/modals";
import {filterEmptyParams} from "../utils/commons";
import {findJobsV2} from "../actions/jobsV2";

const DEFAULT_PARAMS = Object.freeze({
  limit: 20,
  offset: 0
});

const transformParams = (defaultParams, queryParams) => {
  const params = {
    ...defaultParams,
    ...queryParams,
  };

  if(Object.hasOwn(params, 'byStatus')) {
    params.byStatus = parseInt(params.byStatus);
  }

  if(Object.hasOwn(params, 'limit')) {
    params.limit = parseInt(params.limit);
  }

  if(Object.hasOwn(params, 'offset')) {
    params.offset = parseInt(params.offset);
  }

  return {
    ...params,
  };
}

const initializeParams = (queryParams) => {
  return transformParams(DEFAULT_PARAMS, queryParams);
}

const useJobsGrid = () => {
  const jobsV2 = useSelector(state => state.jobsV2);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useQueryParams();
  const [queryParams, setQueryParams] = useState(() => initializeParams(params));

  const fetchJobsV2 = useCallback(async () => {
    const filteredParams = filterEmptyParams(queryParams);
    await dispatch(findJobsV2(filteredParams));
  }, [dispatch, queryParams]);

  // sync grid state with URL
  useEffect(() => {
    history.replace({search: createQueryStr(queryParams)});
  }, [queryParams, history]);

  useEffect(() => {
    fetchJobsV2()
  }, [fetchJobsV2]);

  const handleChangeQueryParams = (newParams) => {
    setQueryParams(prevParams => ({
      ...prevParams,
      ...newParams,
    }));
  };

  const handleOpenRemoveJobPostModal = (job) => {
    dispatch(
      setModalRemoveJobPost({
        show: true,
        data: job,
      })
    )
  };

  const handleRedirectToJobPage = (job) => {
    history.push(`/job/${job.jobId}`);
  }

  const handleRedirectToJobPostingPage = (job) => {
    history.push(`/job/details/${job.jobId}`);
  }

  const handleRedirectToCandidatesPage = (job) => {
    const queryString = createQueryStr(queryParams);
    // store current page query string in location state for redirecting back and restoring query params
    history.push(`/employer/candidates/${job.jobId}`, {prevPageQueryString: queryString});
  }

  return {
    jobsV2,
    queryParams,
    handleChangeQueryParams,
    handleOpenRemoveJobPostModal,
    handleRedirectToJobPage,
    handleRedirectToJobPostingPage,
    handleRedirectToCandidatesPage,
  }
}

export default useJobsGrid
