function IconTermsAndConditions({width = 44, height = 54, className = '', color = '#000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 44 54" className={className}>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
            d="M27 2H7a4.99996 4.99996 0 0 0-3.53553 1.46447A4.99996 4.99996 0 0 0 2 7v40c0 1.3261.52678 2.5979 1.46447 3.5355A4.99995 4.99995 0 0 0 7 52h30c1.3261 0 2.5979-.5268 3.5355-1.4645C41.4732 49.5979 42 48.3261 42 47V17M27 2l15 15M27 2v15h15M32 29.5H12m20 10H12m5-20h-5"/>
    </svg>
  )
}

export default IconTermsAndConditions;
