import BaseCard from "./BaseCard";
import IconAddNewJob from "../Icons/IconAddNewJob";
import {useHistory} from "react-router-dom";

function PostNewJobCard() {
  const history = useHistory();

  const handleRedirectToNewJobPage = () => {
    history.push('/job/new')
  }

  return (
    <BaseCard
      icon={<IconAddNewJob color='#fff' />}
      title='Post a New Job'
      content='Create a new job post and receive CPA resume submissions'
      onClick={handleRedirectToNewJobPage}
    />
  )
}

export default PostNewJobCard;
