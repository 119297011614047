import RnrModal from "./RnrModal";
import Feedback from "./Feedback/Feedback";
import AssessmentResult from "./AssessmentResult";
import FeedbackDetails from "./Feedback/FeedbackDetails";
import {calculateColorByScore, returnTextForArrayValue} from "../../utils/commons";
import jobFunctions from "../../data/jobFunctions";
import industries from "../../data/industries";
import AssessmentQueue from "./AssessmentQueue";
import FeedbackErrorV2 from "./Feedback/FeedbackErrorV2";
import Auth from "../../utils/auth";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setModalAssessFeedbackShow} from "../../actions/modals";
import {fetchJobDetails} from "../../actions/jobs";
import {fetchResumeAssessments} from "../../actions/candidates";
import {fetchRecruiterDetails} from "../../actions/recruiters";
import {toast} from "react-toastify";

const getCandidateDetails = (candidate) => {
  if(!candidate) return null;

  const details = [];

  details.push({label: 'Name', value: `${candidate.firstName} ${candidate.lastName}`});
  details.push({label: 'Email', value: candidate.email});

  return details;
}

const getRecruiterDetails = (recruiter) => {
  if(!recruiter) return null;

  const details = [];

  if(!recruiter) return null;

  details.push({label: 'Name', value: `${recruiter.firstName} ${recruiter.lastName}`});
  details.push({label: 'Email', value: recruiter.email});
  details.push({label: 'Phone', value: recruiter.phoneNumber});

  return details;
}

const getJobDetails = (jobDetails) => {
  if(!jobDetails) return null;

  const details = [];

  details.push({label: 'Title', value: jobDetails.jobTitle});
  details.push({label: 'Job Function', value: returnTextForArrayValue(jobFunctions, jobDetails.jobFunction)});
  details.push({label: 'Industry', value: returnTextForArrayValue(industries, jobDetails.industry)});

  return details;
}

const useFetchFullAssessmentForCandidate = ({candidate, isFetchRecruiter = true}) => {
  // Copied api call from CandidateAssessmentModal.js
  const [jobDetails, setJobDetails] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [recruiterDetails, setRecruiterDetails] = useState(null);
  const [assessmentQueue, setAssessmentQueue] = useState(null);

  const clearAssessmentState = () => {
    setAssessment(null);
    setAssessmentQueue(null);
  }

  useEffect(() => {
    async function fetchData() {
      const jobRequest = fetchJobDetails(candidate.jobId);
      const assessmentRequest = fetchResumeAssessments(candidate.candidateId);

      const requests = [jobRequest, assessmentRequest];

      if (isFetchRecruiter) {
        const recruiterRequest = fetchRecruiterDetails(candidate.recruiterId);
        requests.push(recruiterRequest);
      }

      const [jobData, assessmentData, recruiterData] = await Promise
        .all(requests)
        .catch((err) => {
          toast.error(`Failed fetching data: ${err.message}'`);
        });

      if (!ignore) {
        setJobDetails(jobData[0]);
        if (isFetchRecruiter) {
          setRecruiterDetails(recruiterData.results[0]);
        }
        const data = assessmentData.items[0];

        clearAssessmentState();
        switch (assessmentData?._type) {
          case 'assessmentQueue':
            setAssessmentQueue(data);
            break;
          case 'assessments':
            setAssessment(data);
            break;
          default:
            console.error('[CandidateAssessmentModal]: unknown type of assessmentData', assessmentData);
        }
      }
    }

    if (!candidate?.jobId) return;
    let ignore = false;
    fetchData();
    return () => { ignore = true; };

  }, [candidate]);

  return {
    jobDetails,
    assessment,
    recruiterDetails,
    assessmentQueue,
  }
}

const AiAssessmentFeedbackModal = ({...props}) => {
  // const {jobId} = useParams();
  const feedbackModal = useSelector(state => state.modals.feedbackModal);
  const dispatch = useDispatch();

  const candidate = feedbackModal.data;

  const auth = Auth.getInstance();
  const scope = auth.getScope();
  const isAdmin = scope === 'admin';
  const isEmployer = scope === 'employer';

  const {
    jobDetails,
    assessment,
    recruiterDetails,
    assessmentQueue,
  } = useFetchFullAssessmentForCandidate({candidate, isFetchRecruiter: isAdmin});

  const candidateDetailsData = getCandidateDetails(candidate);
  const recruiterDetailsData = getRecruiterDetails(recruiterDetails);
  const jobDetailsData = getJobDetails(jobDetails);

  const handleHide = () => {
    dispatch(setModalAssessFeedbackShow({
      show: false,
      data: null,
    }));
  }

  if (!candidate || !jobDetails) {
    return null;
  }


  return (
    <RnrModal
      show={feedbackModal.show}
      onHide={handleHide}
      {...props}
    >
      <Feedback
        title='Feedback'
        proccessedAt={assessment?.lastProcessedAt}
        style={{maxWidth: '600px'}}
      >
        {isEmployer ?
          <FeedbackDetails title='Candidate Details' data={candidateDetailsData} /> : (
            <>
              <FeedbackDetails title='Candidate Details' data={candidateDetailsData} />
              <FeedbackDetails title='Recruiter Details' data={recruiterDetailsData} />
              <FeedbackDetails title='Job Details' data={jobDetailsData} />
            </>
          )
        }
        {assessment?.resumeAssessment.length
          ? <AssessmentResult
              assessment={assessment.resumeAssessment}
              score={assessment.score}
              color={calculateColorByScore(assessment.score)}
              isScoreVisible={true}
            />
          : assessment?.errors?.length
            ? <FeedbackErrorV2 errorName={assessment.errors[assessment.errors.length - 1]} />
            : null
        }
        {assessmentQueue && <AssessmentQueue assessmentQueue={assessmentQueue} />}
        {!assessment && !assessmentQueue
          ? <FeedbackErrorV2
            errorName='No Resume Assessment found'
            errorMsg='Please try again or contact with us' />
          : null}
      </Feedback>
    </RnrModal>
  )
}

// For skip rendering hidden AiAssessmentFeedbackModal component
const AiAssessmentFeedbackModalContainer= () => {
  const feedbackModal = useSelector(state => state.modals.feedbackModal);

  if(!feedbackModal.show) return null;

  return <AiAssessmentFeedbackModal />
}

export default AiAssessmentFeedbackModalContainer
