import {AI_ASSESSMENT_STATUS, calculateColorByScore} from "../../utils/commons";
import {clsx} from "clsx";
import * as Icon from "react-bootstrap-icons";
import React from "react";

const StatusIcon = (status, file) => {
  const score = file?.assessment?.score ?? 0;
  switch (status) {
    case AI_ASSESSMENT_STATUS.IN_PROGRESS:
      // TODO: replace with react spinner
      return <div className="assessment-loader assessment-loader--small"></div>;
    case AI_ASSESSMENT_STATUS.FAILED:
      return <Icon.XCircle style={{flexShrink: 0}} size={16} color='var(--color-danger)' />;
    case AI_ASSESSMENT_STATUS.DONE:
      return <Icon.ArrowUpRightCircleFill style={{flexShrink: 0}} size={16} color={calculateColorByScore(score)} />;
    default:
      return null;
  }
}

const UploadedResumes = ({acceptedPdfFiles, setActiveAcceptedPdfFile}) => {
  const handleFileClick = (file) => {
    if(!file.status) return;
    return setActiveAcceptedPdfFile(file.path);
  }

  const isClickableFile = (file) => file.status;
  const isEmptyIcon = (file) => !file.status && !file.parsingError;
  const isErrorIcon = (file) => file.status === AI_ASSESSMENT_STATUS.FAILED;

  if(!acceptedPdfFiles?.length) return null;
  return (
    <div className='uploaded-resumes'>
      <h5 className="uploaded-resumes__title">File Name</h5>
      <ul className='uploaded-resumes__list'>
        {acceptedPdfFiles.map(file => {

          return (
            <li
              key={file.path}
              className={clsx(
                'uploaded-resumes__list-item',
                {
                  'uploaded-resumes__list-item--error': isErrorIcon(file),
                  'uploaded-resumes__list-item--clickable': isClickableFile(file),
                  'uploaded-resumes__list-item--empty-status': isEmptyIcon(file),
                }
              )}
              onClick={() => handleFileClick(file)}
            >
              {file.parsingError ?
                <Icon.ArrowUpRightCircleFill style={{flexShrink: 0}} size={16} color='var(--color-warning)'/>  :
                StatusIcon(file.status, file)}
              <span>{file.path}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default UploadedResumes;
