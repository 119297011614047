import {Button, Col, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {rejectCandidateByAdmin} from "../../actions/candidatesForAdmin";
import {useDispatch, useSelector} from "react-redux";
import {setModalRejectCandidate} from "../../actions/modals";
import axios, {Axios, AxiosError} from "axios";
import auth from "../../utils/auth";
import {toast} from "react-toastify";

const RejectCandidateModalContainer = () => {
  const rejectCandidateModal = useSelector(state => state.modals.rejectCandidateModal);

  if(!rejectCandidateModal.show) return null;

  return (
    <RejectCandidateModal />
  )
}

const RejectCandidateModal = () => {
  const dispatch = useDispatch();

  const [rejectReason, setRejectReason] = useState('');
  const rejectCandidate = useSelector(state => state.modals.rejectCandidateModal.data || {});

  const closeRejectCandidate = () => {
    setRejectReason('');
    dispatch(setModalRejectCandidate({show: false, data: null}));
  }

  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value);
  }

  const handleRejectCandidate = async(e, { candidateId, jobId }, reason) => {
    try {
      e && e.stopPropagation();
      await axios.post(`${process.env.PUBLIC_URL}/api/v1/candidates/rejectCandidateByAdmin`, {
        candidateId, jobId, reason
      }, {
        headers: {
          Authorization: "Bearer " + auth.getInstance().getAccessToken(),
        }
      });
      toast.success("Candidate rejected successfully");
      // For avoid refetch CandidatesGrid data, we just reload the page
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (e) {
      if(e instanceof AxiosError) {
        toast.error(`Failed to reject candidate - ${e.response.data.message}`);
      } else {
        toast.error(`Failed to reject candidate - ${e.message}`);
      }
      console.error("[handleRejectCandidate] - Error: ", e);
    }
  }

  return (
    <Modal show centered onHide={closeRejectCandidate}>
      <Modal.Header closeButton>
        <Modal.Title>Rejecting {rejectCandidate.firstName} {rejectCandidate.lastName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row className="mb-2">
          <Col sm={12}>
            <Form.Label>Reason</Form.Label>
          </Col>
          <Col sm={12}>
            <Form.Control
              as="textarea"
              type="text"
              value={rejectReason}
              onChange={handleRejectReasonChange}
              name="reason"
            />
          </Col>
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="marign-right-10"
          variant="primary"
          disabled={!rejectReason}
          onClick={e => handleRejectCandidate(e, rejectCandidate, rejectReason)}
        >
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RejectCandidateModalContainer;
