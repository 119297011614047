import {AI_ASSESSMENT_STATUS} from "./commons";

export const hasAssessmentInResumeFile = (acceptedPdfFiles) => {
  return acceptedPdfFiles?.some(item => item.assessment?.length);
}

export const hasMultipleResumeFiles = (acceptedPdfFiles) => {
  return acceptedPdfFiles?.length > 1;
}

export const isFinishedProcessingResume = (acceptedPdfFiles) => {
  return acceptedPdfFiles?.some(item =>
    item.status === AI_ASSESSMENT_STATUS.DONE ||
    item.status === AI_ASSESSMENT_STATUS.FAILED ||
    item.parsingError
  );
}

export const printProcessedAt = (file) => {
  return file.processedAt ? new Date(file.processedAt).toISOString() : 'N/A'
}
