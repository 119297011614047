import {find} from "../utils/api";

const EMPLOYER_CITIES_BASE_URL = `${process.env.PUBLIC_URL}/api/v2/jobs/findCitiesForEmployer`;

export const FIND_EMPLOYER_CITIES = "FIND_EMPLOYER_CITIES";
export const FIND_EMPLOYER_CITIES_COMPLETED = "FIND_EMPLOYER_CITIES_COMPLETED";
export const FIND_EMPLOYER_CITIES_FAILED = "FIND_EMPLOYER_CITIES_FAILED";

export const findEmployerCities = find(
  EMPLOYER_CITIES_BASE_URL,
  FIND_EMPLOYER_CITIES,
  FIND_EMPLOYER_CITIES_COMPLETED,
  FIND_EMPLOYER_CITIES_FAILED
);
