import React, {useCallback, useMemo, useState} from "react";
import {debounce} from "../../../utils/commons";
import {FormControl, InputGroup} from "react-bootstrap";
import IconSearch from "../../common/Icons/IconSearch";
import Select from "react-select";
import Auth from "../../../utils/auth";

const EMPLOYER_STATUS_OPTIONS = Object.freeze([
  { value: 'Any', label: 'All' },
  { value: 'ApprovedByAdmin', label: 'Pending' },
  { value: 'RejectedByEmployer', label: 'Rejected' },
  { value: 'InterviewRequested', label: 'Approved' },
]);

const ADMIN_STATUS_OPTIONS = Object.freeze([
  { value: 'Any', label: 'All' },
  { value: 'PendingCandidateConsent', label: 'Pending Candidate Consent'},
  { value: 'AwaitingAdminApproval', label: 'Awaiting Admin Approval'},
  { value: 'ApprovedByAdmin', label: 'Approved by Admin' },
  { value: 'RejectedByAdmin', label: 'Rejected by Admin' },
  { value: 'RejectedByEmployer', label: 'Rejected by Employer' },
  { value: 'InterviewRequested', label: 'Interview Requested' },
]);

const initializeState = (queryParams) => ({
  status: queryParams.status || null,
  search: queryParams.search || '',
});

const handleStatusChange = (setStatus, onChange) => (selectedOption) => {
  const newStatus = selectedOption?.value ?? null;
  setStatus(selectedOption);
  onChange({ status: newStatus, offset: 0 });
};

const handleSearch = (setSearch, debouncedHandleSearch) => (e) => {
  const newSearch = e.target.value;
  setSearch(newSearch);
  debouncedHandleSearch({ search: newSearch, offset: 0 });
};

const FilterBar = ({title, queryParams, onChange}) => {
  const auth = Auth.getInstance();
  const scope = auth.getScope();
  const isAdmin = scope === 'admin';

  const statusOptions = isAdmin ? ADMIN_STATUS_OPTIONS : EMPLOYER_STATUS_OPTIONS;

  const [{ status, search }, setState] = useState(() => initializeState(queryParams));
  const setStatus = (status) => setState((prevState) => ({ ...prevState, status }));
  const setSearch = (search) => setState((prevState) => ({ ...prevState, search }));

  const selectedStatus = useMemo(() => statusOptions.find(option => option.value === status), [status, statusOptions]);

  const debouncedHandleSearch = useCallback(debounce(onChange), [onChange]);
  const memoizedHandleStatusChange = useCallback(handleStatusChange(setStatus, onChange), [setStatus, onChange]);
  const memoizedHandleSearch = useCallback(handleSearch(setSearch, debouncedHandleSearch), [setSearch, debouncedHandleSearch]);

  return (
    <div className='ec-filterbar'>
      <h3 className='ec-filterbar__title'>{title}</h3>
      <div className="ec-filterbar__filter">
        <InputGroup className='ec-filterbar__search fb-search fb-search--radius fb-search--font'>
          <InputGroup.Prepend>
            <InputGroup.Text color='red' id="search-icon"><IconSearch width={24} height={24}/></InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            id="search"
            size='sm'
            type='search'
            value={search}
            onChange={memoizedHandleSearch}
            placeholder="Search for Candidate"
            aria-label="Search"
            aria-describedby="search-icon"
          />
        </InputGroup>
        <Select
          className='ec-filterbar__status'
          isClearable={true}
          value={selectedStatus}
          onChange={memoizedHandleStatusChange}
          options={statusOptions}
          placeholder='Filter By Status'
        />
      </div>
    </div>
  )
};

export default FilterBar;
