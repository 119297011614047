import {clsx} from "clsx";

function BaseCard({icon, title, content, variant = 'default', onClick}) {
  return (
    <div className={clsx('base-card', {
      [`base-card--${variant}`]: !!variant
    })} onClick={onClick}>
      <div className="base-card__wrp">
        {icon}
        <h3 className='base-card__title'>{title}</h3>
        <p className='base-card__content'>{content}</p>
      </div>
    </div>
  )
}

export default BaseCard;
