import {AI_ASSESSMENT_STATUS} from "../../../utils/commons";
import FeedbackContacts from "./FeedbackContacts";
import React from "react";

const FeedbackContactsContainer = ({acceptedPdfFiles, activeAcceptedPdfFile}) => {
  const activeFile = activeAcceptedPdfFile ?
    acceptedPdfFiles.find(item => item.path === activeAcceptedPdfFile) :
    acceptedPdfFiles[0];

  if (!activeFile || !activeFile.status || activeFile.status === AI_ASSESSMENT_STATUS.IN_PROGRESS) {
    return null;
  }

  let job;
  try {
    job = JSON.parse(activeFile.job);
  } catch (error) {
    console.error('[renderContact] - error parsing job from file', error);
    return null;
  }

  const contacts = [{ value: job.jobTitle, label: 'Job title' }];

  return <FeedbackContacts data={contacts} />;
}

export default FeedbackContactsContainer;
