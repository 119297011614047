import React from "react";

const styles = {
  fontSize: '20px',
  fontWeight: '600',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100px',
}

const GridNoData = () => {
  return (
    <div style={styles}>No Results Found</div>
  )
}

export default GridNoData;
