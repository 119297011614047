import {Tab, Tabs} from "react-bootstrap";
import AssessmentResult from "./AssessmentResult";
import {useActiveAssessmentPdfFile} from "../../context/ActiveAssessmentPdfFileProvider";
import React, {useEffect} from "react";
import {AI_ASSESSMENT_STATUS, calculateColorByScore} from "../../utils/commons";
import FeedbackErrorV2 from "./Feedback/FeedbackErrorV2";
import {printProcessedAt} from "../../utils/assessment";

const renderAssessmentResult = (item) => {
  if (item.parsingError) {
    return (
      <FeedbackErrorV2
        errorName={`Parsing Failed`}
        errorMsg={`'${item.path}'`}
      />
    );
  }

  if (item.status === AI_ASSESSMENT_STATUS.FAILED) {
    return (
      <FeedbackErrorV2
        proccessedAt={printProcessedAt(item)}
        errorName={item.errorName}
        errorMsg={item.errorMsg}
      />
    );
  }

  if (item.status === AI_ASSESSMENT_STATUS.DONE) {
    if(item.assessment?.resumeAssessment.length) {
      return (
        <AssessmentResult
          assessment={item.assessment.resumeAssessment}
          score={item.assessment.score}
          color={calculateColorByScore(item.assessment.score)}
        />
      );
    }

    return (
      <FeedbackErrorV2
        proccessedAt={printProcessedAt(item)}
        errorName='No Resume Assessment found'
        errorMsg='Please try again or contact with us'
      />
    );
  }

  return null;
}

function AssessmentSwitch({acceptedPdfFiles}) {
  const {activeAcceptedPdfFile, setActiveAcceptedPdfFile} = useActiveAssessmentPdfFile();

  useEffect(() => {
    // Set the first accepted pdf file as active
    setActiveAcceptedPdfFile(acceptedPdfFiles[0]?.path);
  }, [acceptedPdfFiles]);

  return (
    <Tabs
      activeKey={activeAcceptedPdfFile}
      transition={false}
      onSelect={setActiveAcceptedPdfFile}
    >
      {acceptedPdfFiles.map((item, index) => {
        const tabTitle = `Assessment ${index + 1}`;
        const processedAt = printProcessedAt(item);

        return (
          <Tab eventKey={item.path} title={tabTitle} key={item.path}>
            <div className="assessment-tab-wrp">
              <div className="assessment-tab">
                <h3 className='assessment-tab__title'>{tabTitle}</h3>
                <p className='assessment-tab__txt assessment-tab__txt--margin'>File Scanned: <span>{item.path}</span></p>
                <p className='assessment-tab__txt'>Processed At: <span>{processedAt}</span></p>
              </div>
              {renderAssessmentResult(item)}
            </div>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default AssessmentSwitch;
