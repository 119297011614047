import BaseCard from "./BaseCard";
import IconQuestion from "../Icons/IconQuestion";
import {useDispatch, useSelector} from "react-redux";
import Auth from "../../../utils/auth";
import {useEffect} from "react";
import {findEmployer} from "../../../actions/employers";

function QuestionsCard() {
  const dispatch = useDispatch();

  const auth = Auth.getInstance();
  const userId = auth.getUserId();

  const employer = useSelector(state => state.employers.employer);

  useEffect(() => {
    (async () => {
      await dispatch(findEmployer({ employerId: userId }))
    })()
  }, [dispatch, userId]);

  const handleClick = async () => {
    const subject = encodeURIComponent('Inquiry on a Candidate');
    const body = encodeURIComponent(`Hi Rand\n\n${employer.companyName} has a question about a candidate on RNR's dashboard. Can you please call us back to discuss further.\n\nThanks\n\n${(employer.firstName + ' ' + employer.lastName).trim()}`);
    const mailtoLink = `mailto:rand@recruitnrefer.com?subject=${subject}&body=${body}`;

    const a = document.createElement('a');
    a.href = mailtoLink;
    a.target = '_blank';
    a.click();
  }

  return (
    <BaseCard
      variant='light'
      icon={<IconQuestion />}
      title='Questions?'
      content='Click to send us a quick email and your Account Manager will get back to you.'
      onClick={handleClick}
    />
  )
}

export default QuestionsCard;
