import BaseCard from "./BaseCard";
import IconStartAiAssessment from "../Icons/IconStartAiAssessment";
import {useDispatch} from "react-redux";
import {setModalAssessResumeShow} from "../../../actions/modals";

function StartAIAssessmentCard() {
  const dispatch = useDispatch();

  const handleShowAssessResume = () => {
    dispatch(setModalAssessResumeShow({show: true}));
  }

  return (
    <BaseCard
      icon={<IconStartAiAssessment color='#fff' />}
      title='Start AI Assessment'
      content='Evaluate Candidate Suitability for the role'
      onClick={handleShowAssessResume}
    />
  )
}

export default StartAIAssessmentCard;
