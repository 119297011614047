import {ProgressBar} from "react-bootstrap";
import {clsx} from "clsx";

function AssessmentResult({assessment = [], score = 80, color = 'green', isScoreVisible}) {
  return (
    <>
      <div className="assessment-score">
        <div className="assessment-score__title">Score</div>
        <div className="assessment-score__row">
          <span style={{color}}>{score}%</span>
          <ProgressBar className='assessment-score__progress' now={score}>
            <div style={{backgroundColor: color, width: `${score}%`}} />
          </ProgressBar>
        </div>
      </div>
      <div className={clsx('assessment-result', {
        'assessment-result--scroll': isScoreVisible,
      })}>
        <div className="assessment-result__top">
          <span>Requirement</span>
          <span>Feedback</span>
        </div>
        <ul className="assessment-result__list">
          {assessment.map(item => {
            return (
              <li className="assessment-result__item" key={item.requirement}>
                <div className="assessment-result__requirement">{item.requirement}</div>
                <div className="assessment-result__feedback">
                  <span className="assessment-result__status" style={{background: item.status}}></span>
                  <span>{item.feedback}</span>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default AssessmentResult;
