import {
  FIND_CANDIDATES_FOR_JOB,
  FIND_CANDIDATES_FOR_JOB_COMPLETED,
  FIND_CANDIDATES_FOR_JOB_FAILED
} from "../actions/candidatesForJob";
import {PAGE_INIT} from "../actions/pages";

export const defaultState = Object.freeze({
  candidates: [],
  totalCount: 0,
  findingCandidates: false,
  findCandidatesError: "",
});

const candidatesForJobReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_INIT: {
      return {...defaultState};
    }

    case FIND_CANDIDATES_FOR_JOB: {
      return {
        ...state,
        findingCandidates: true,
        findCandidatesError: "",
      }
    }

    case FIND_CANDIDATES_FOR_JOB_COMPLETED: {
      const {results, total} = action.payload;
      return {
        ...state,
        findingCandidates: false,
        candidates: results,
        totalCount: total,
      }
    }

    case FIND_CANDIDATES_FOR_JOB_FAILED: {
      const {message} = action.payload;
      return {
        ...state,
        findingCandidates: false,
        findCandidatesError: message,
      }
    }

    default: {
      return state;
    }
  }
}

export default candidatesForJobReducer;
