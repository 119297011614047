const FeedbackDetails = ({ title, data }) => {
  if(!data) return null;

  return (
    <div className='feedback-details'>
      <div className='feedback-details__title'>{title}</div>
      <div className="feedback-details__content">
        {data.map((item) => {
          return (
            <div className="fb-item" key={item.label}>
              <div className="fb-item__label">{item.label}</div>
              <div className="fb-item__txt">{item.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FeedbackDetails;
