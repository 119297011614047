function Feedback({title, proccessedAt, children, ...props}) {
  return (
    <div className="feedback" {...props}>
      <h3 className="feedback__title">{title}</h3>
      {proccessedAt && <p className="feedback__sub">Proccessed at: {proccessedAt}</p>}
      {children && <div className="feedback__body">{children}</div>}
    </div>
  )
}

export default Feedback;
