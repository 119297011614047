function IconStartAiAssessment({width = 48, height = 53, className = '', color = '#000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 48 53" className={className}>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
            d="M21.6651 51h17.48c1.7385 0 3.4058-.704 4.6351-1.9571 1.2293-1.253 1.9199-2.9526 1.9199-4.7247V17.5909L30.4051 2h-17.48c-1.7385 0-3.40582.70398-4.63513 1.95706-1.2293 1.25308-1.91991 2.95263-1.91991 4.72476V19.8182"/>
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
            d="M30.405 10.9091c0 1.7721.6906 3.4717 1.9199 4.7247 1.2293 1.2531 2.8966 1.9571 4.6351 1.9571h8.7401L30.405 2v8.9091ZM2 37.6364h17.48M10.7399 28.7273v17.8182"/>
    </svg>
  )
}

export default IconStartAiAssessment;
