import {Modal} from "react-bootstrap";
import React from "react";
import * as Icon from "react-bootstrap-icons";

/*
* Redesigned Modal component, contains new UI and basic functionality
* */

const CloseButton = ({onHide}) => {
  return (
    <button className="redesign-modal__close" onClick={onHide}>
      <Icon.XLg size={26} color='var(--color-primary)'/>
    </button>
  )
}

const RnrModal = ({ show, onHide, children, ...props }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="redesign-modal"
      dialogClassName='redesign-modal__dialog'
      contentClassName='redesign-modal__content'
      centered
      {...props}
    >
      <CloseButton onHide={onHide} />
      {children}
    </Modal>
  )
}

export default RnrModal
