import {Button} from "react-bootstrap";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Select from "react-select";
import NewFileDropZone from "./NewFileDropZone";
import {MAX_FILES} from "../../utils/commons";
import Feedback from "./Feedback/Feedback";
import ActiveAssessmentPdfFileProvider, {useActiveAssessmentPdfFile} from "../../context/ActiveAssessmentPdfFileProvider";
import {
  hasAssessmentInResumeFile,
  hasMultipleResumeFiles,
  isFinishedProcessingResume,
  printProcessedAt
} from "../../utils/assessment";
import RnrModal from "./RnrModal";
import useAssessmentResumes from "../../hooks/useAssessmentResumes";
import FeedbackAssessment from "./Feedback/FeedbackAssessment";
import UploadedResumes from "./UploadedResumes";
import FeedbackContactsContainer from "./Feedback/FeedbackContactsContainer";
import {useDispatch, useSelector} from "react-redux";
import {setModalAssessResumeShow} from "../../actions/modals";
import {findJobsV2, setJobForAssessment} from "../../actions/jobsV2";
import useQueryParams from "../../hooks/useQueryParams";
import FeedbackContacts from "./Feedback/FeedbackContacts";

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    width: '100%',
    minHeight: '48px'
  }),
}

const useSelect = ({ items, defaultValue, onChange }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const options = useMemo(() => {
    return items.map(item => ({
      value: item.jobId,
      label: item.jobTitle,
    }));
  }, [items]);

  useEffect(() => {
    if(defaultValue) {
      const selectedItem = items.find(item => item.jobId === defaultValue.jobId);

      if(selectedItem) {
        setSelectedValue({
          value: selectedItem.jobId,
          label: selectedItem.jobTitle,
        });
        onChange(selectedItem);
      }
    }
  }, [items, defaultValue]);

  const handleSelectChange = (option) => {
    if (!option) {
      setSelectedValue(null);
      onChange(null);
      return;
    }

    setSelectedValue(option);
    const selectedItem = items.find(item => item.jobId === option.value);

    onChange(selectedItem);
  };

  return {
    value: selectedValue,
    options,
    handleSelectChange,
  };
};

function AiAssessmentModal() {
  const jobForCandidates = useSelector(state => state.jobsV2.jobForCandidates);
  const jobsV2 = useSelector(state => state.jobsV2);
  const assessResumeModal = useSelector(state => state.modals.assessResumeModal);

  const {
    acceptedPdfFiles,
    handleAcceptedFile,
    handleAssessResume,
    hidePdfAIAssessment
  } = useAssessmentResumes()

  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    setFeedbacks(acceptedPdfFiles);
  }, [acceptedPdfFiles]);

  const dispatch = useDispatch();
  const params = useQueryParams();

  const fetchJobsV2 = useCallback(async (params) => {
    await dispatch(findJobsV2(params));
  }, [dispatch]);

  useEffect(() => {
    fetchJobsV2({
      limit: 20,
      offset: 0,
      byStatus: 3
    });
  }, [fetchJobsV2]);

  // force rerender to reset select value when start new analysis
  const [selectKey, setSelectKey] = useState(1);
  const forceRerender = () => setSelectKey(selectKey + 1);

  const handleChangeJobForAssessment = (job) => {
    dispatch(setJobForAssessment(job))
    setFeedbacks([]);
  }

  // TODO: need refactor
  const {
    value: jobValue,
    options: jobsOptions,
    handleSelectChange: handleJobChange,
  } = useSelect({
    items: jobsV2.jobs,
    defaultValue: jobForCandidates,
    onChange: handleChangeJobForAssessment
  });

  // Selected assessment file(for generating multiple assessments)
  const {activeAcceptedPdfFile, setActiveAcceptedPdfFile} = useActiveAssessmentPdfFile();

  const handleHideModal = async () => {
    // refetch jobs for updating grid
    await fetchJobsV2(params);
    setActiveAcceptedPdfFile(null);
    handleChangeJobForAssessment(null);
    handleJobChange(null);
    hidePdfAIAssessment();
    dispatch(setModalAssessResumeShow({show: false}));
  }

  const isFeedbackCleared = useMemo(() => acceptedPdfFiles.length && !feedbacks.length, [acceptedPdfFiles, feedbacks.length]);
  const isDisabledAnalyzing = useMemo(() => {
    if(!jobValue) return true;
    if(isFeedbackCleared) return false;
    return acceptedPdfFiles.every(file => file.status);
  }, [acceptedPdfFiles, jobValue, isFeedbackCleared]);

  const isSingleAssessment = hasAssessmentInResumeFile(acceptedPdfFiles) && !hasMultipleResumeFiles(acceptedPdfFiles);
  const hasProcessedAt = isSingleAssessment && acceptedPdfFiles[0]?.processedAt;
  const singleAssessmentProcessedAt = hasProcessedAt ? printProcessedAt(acceptedPdfFiles[0]) : null;

  const showStartNewAnalysis = useMemo(() => isFinishedProcessingResume(acceptedPdfFiles), [acceptedPdfFiles]);
  const handleStartNewAnalysis = () => {
    setActiveAcceptedPdfFile(null);
    handleChangeJobForAssessment(null);
    handleJobChange(null);
    hidePdfAIAssessment();
    forceRerender();
  }


  return (
    <RnrModal
      show={assessResumeModal.show}
      onHide={handleHideModal}
      >
      <div className='ai-assessment-layout'>
        <div className='assessment-upload'>
          <div className="assessment-upload__header">
            <h3 className='assessment-upload__title'>AI Assessment</h3>
            <p className='assessment-upload__sub'>Check the compatibility of candidates to your jobs</p>
          </div>
          <div className='assessment-upload__row'>
            <Select
              styles={selectStyles}
              value={jobValue}
              onChange={handleJobChange}
              options={jobsOptions}
              placeholder='Select Job'
            />
          </div>
          <div className="assessment-upload__body">
            <UploadedResumes acceptedPdfFiles={acceptedPdfFiles} setActiveAcceptedPdfFile={setActiveAcceptedPdfFile} />
            <NewFileDropZone
              maxFiles={MAX_FILES}
              onAcceptedFile={handleAcceptedFile}
            />
          </div>
          <div className='assessment-upload__footer'>
            {showStartNewAnalysis && (
              <Button
                bsPrefix='button'
                variant='primary'
                size='lg'
                onClick={handleStartNewAnalysis}
              >
                Start New Analysis
              </Button>
            )}
            <Button
              bsPrefix='button'
              variant='primary'
              size='lg'
              disabled={isDisabledAnalyzing}
              onClick={() => handleAssessResume(jobsV2.jobForAssessment, isFeedbackCleared)}
            >
              Start Analyzing
            </Button>
          </div>
        </div>
        {acceptedPdfFiles.length ? (
          <Feedback
            title='Feedback'
            proccessedAt={singleAssessmentProcessedAt}
          >
            {
              isFinishedProcessingResume(feedbacks) ? (
                <FeedbackContactsContainer acceptedPdfFiles={acceptedPdfFiles} activeAcceptedPdfFile={activeAcceptedPdfFile}/>
              ) : (
                <FeedbackContacts data={[{value: jobValue?.label, label: 'Job title'}]}/>
              )
            }
            {isFinishedProcessingResume(feedbacks) ? (
              <FeedbackAssessment acceptedPdfFiles={feedbacks}/>
            ) : (
              <div className='error-txt'>No Resume Assessment found</div>
        )}
      </Feedback>
      ) : null}
    </div>
    </RnrModal>
  )
}

// For skip rendering hidden AiAssessmentModal component
const AiAssessmentModalContainer = () => {
  const assessResumeModal = useSelector(state => state.modals.assessResumeModal);

  if(!assessResumeModal.show) return null;

  return (
    <ActiveAssessmentPdfFileProvider>
      <AiAssessmentModal />
    </ActiveAssessmentPdfFileProvider>
  )
}

export default AiAssessmentModalContainer;
