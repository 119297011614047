export const SET_MODAL_ASSESS_RESUME_SHOW = 'SET_MODAL_ASSESS_RESUME_SHOW';
export const SET_MODAL_ASSESS_FEEDBACK_SHOW = 'SET_MODAL_ASSESS_FEEDBACK_SHOW';
export const SET_MODAL_REMOVE_JOB_POST = 'SET_MODAL_REMOVE_JOB_POST';
export const SET_MODAL_REJECT_CANDIDATE = 'SET_MODAL_REJECT_CANDIDATE';
export const SET_MODAL_SCHEDULE_INTERVIEW = 'SET_MODAL_SCHEDULE_INTERVIEW';

const createModalAction = (type) => {
  return (payload) => ({
    type: type,
    payload: payload
  })
}

export const setModalAssessResumeShow = createModalAction(SET_MODAL_ASSESS_RESUME_SHOW);
export const setModalAssessFeedbackShow = createModalAction(SET_MODAL_ASSESS_FEEDBACK_SHOW);
export const setModalRemoveJobPost = createModalAction(SET_MODAL_REMOVE_JOB_POST);
export const setModalRejectCandidate = createModalAction(SET_MODAL_REJECT_CANDIDATE);
export const setModalScheduleInterview = createModalAction(SET_MODAL_SCHEDULE_INTERVIEW);
