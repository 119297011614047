import {
  SET_MODAL_ASSESS_FEEDBACK_SHOW,
  SET_MODAL_ASSESS_RESUME_SHOW, SET_MODAL_REJECT_CANDIDATE,
  SET_MODAL_REMOVE_JOB_POST, SET_MODAL_SCHEDULE_INTERVIEW
} from "../actions/modals";

export const defaultState = Object.freeze({
  assessResumeModal: {
    show: false,
    data: null,
  },
  feedbackModal: {
    show: false,
    data: null,
  },
  removeJobPostModal: {
    show: false,
    data: null,
  },
  rejectCandidateModal: {
    show: false,
    data: null,
  },
  scheduleInterviewModal: {
    show: false,
    data: null,
  },
});

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MODAL_ASSESS_RESUME_SHOW: {
      return {
        ...state,
        assessResumeModal: action.payload,
      }
    }
    case SET_MODAL_ASSESS_FEEDBACK_SHOW: {
      return {
        ...state,
        feedbackModal: action.payload,
      }
    }
    case SET_MODAL_REMOVE_JOB_POST: {
      return {
        ...state,
        removeJobPostModal: action.payload,
      }
    }
    case SET_MODAL_REJECT_CANDIDATE: {
      return {
        ...state,
        rejectCandidateModal: action.payload,
      }
    }
    case SET_MODAL_SCHEDULE_INTERVIEW: {
      return {
        ...state,
        scheduleInterviewModal: action.payload,
      }
    }
    default:
      return state;
  }
}

export default modalsReducer;
