import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {initPage} from "../actions/pages";

const useInitPage = (page = '', query = {}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initPage(page, query));
  }, [dispatch]);
};

export default useInitPage;
